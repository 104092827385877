var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import './form-text-input.scss';
import renderTemplate from './_form-text-input.hbs';
import { Pattern, getPropertiesFromClassName, formatStringAsMobileNumber, removeUndefinedFieldsFromObject, NO_PATTERN_BUSINESS_LOGIC, } from '@vfde-brix/ws10/core';
import { FORM_TEXT_INPUT_CLASSNAME, FORM_TEXT_INPUT_CLASSNAME_DISABLED, FORM_TEXT_INPUT_CLASSNAME_ERROR, FORM_TEXT_INPUT_CLASSNAME_SUCCESS, FORM_TEXT_INPUT_CLASSNAME_WARN, FORM_TEXT_INPUT_CLASSNAME_WITH_SYSTEM_ICON, FORM_TEXT_INPUT_INPUT_CLASSNAME, FORM_TEXT_INPUT_MOBILE_NUMBER, FORM_TEXT_INPUT_NOTIFICATION_ICON_CLASSNAME, FORM_TEXT_INPUT_SYSTEM_ICON_CLASSNAME, } from './Constants';
import { addFormElementEventHandlers, elementRemoveStateOnDownEventHandler, } from './shared/FormElement';
import { FORM_ELEMENT_STATES } from './shared/Constants';
import { createNotificationIcon, } from '@vfde-brix/ws10/notification-icon';
import { createSystemIcon, } from '@vfde-brix/ws10/system-icon';
/**
 * Input field component
 */
var FormTextInput = /** @class */ (function (_super) {
    __extends(FormTextInput, _super);
    function FormTextInput() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        /**
         * Format values entered in mobile number text box as a mobile number
         */
        _this.handleAutoFormatMobileNumber = function (event) {
            var input = event.currentTarget;
            input.value = formatStringAsMobileNumber(input.value);
            _this.update({ stdValue: input.value }, true);
        };
        return _this;
    }
    /**
     * Render component
     */
    FormTextInput.prototype.writeDom = function () {
        this.containerElement.innerHTML = renderTemplate(this.properties);
        this.initDomElements();
    };
    /**
     * returns always an empty object or with the necessary properties.
     * Dynamic: this.properties.optState
     * Static: empty object
     */
    FormTextInput.prototype.getNotificationIconProperties = function () {
        if (this.properties) {
            return {
                optState: this.properties.optState,
                business: NO_PATTERN_BUSINESS_LOGIC,
            };
        }
        return NO_PATTERN_BUSINESS_LOGIC;
    };
    /**
     * returns always an empty object or with the necessary properties.
     * Dynamic: this.properties.optSystemIcon
     * Static: empty object
     */
    FormTextInput.prototype.getSystemIconProperties = function () {
        if (this.properties) {
            return {
                stdName: this.properties.optSystemIcon,
                optSize: 'size-inherit',
                optColor: 'color-monochrome-600',
                business: NO_PATTERN_BUSINESS_LOGIC,
            };
        }
        return NO_PATTERN_BUSINESS_LOGIC;
    };
    /**
     * Returns the input element
     */
    FormTextInput.prototype.getInputElement = function () {
        return this.containerElement.getElementsByClassName(FORM_TEXT_INPUT_INPUT_CLASSNAME)[0];
    };
    /**
     * This helper function to initializing InputComponent on writeDom and for readDom
     *
     * First it gets the properties from other helper functions
     * then call the create function with the properties.
     */
    FormTextInput.prototype.initDomElements = function () {
        var notificationIconProps = this.getNotificationIconProperties();
        this.notificationIconContainerElement = this.containerElement
            .getElementsByClassName(FORM_TEXT_INPUT_NOTIFICATION_ICON_CLASSNAME)[0];
        /* istanbul ignore else */
        if (this.notificationIconContainerElement) {
            this.notificationIconComponent = createNotificationIcon(this.notificationIconContainerElement, notificationIconProps);
        }
        var systemIconProps = this.getSystemIconProperties();
        this.systemIconContainerElement = this.containerElement
            .getElementsByClassName(FORM_TEXT_INPUT_SYSTEM_ICON_CLASSNAME)[0];
        /* istanbul ignore else */
        if (this.systemIconContainerElement) {
            this.systemIconComponent = createSystemIcon(this.systemIconContainerElement, systemIconProps);
        }
    };
    /**
     * Init Events (on change currently handled by parent component as this is not a stand alone component yet)
     */
    FormTextInput.prototype.initEvents = function () {
        var _this = this;
        var element = this.containerElement.getElementsByClassName(FORM_TEXT_INPUT_CLASSNAME)[0];
        var input = this.containerElement.getElementsByClassName(FORM_TEXT_INPUT_INPUT_CLASSNAME)[0];
        addFormElementEventHandlers(this.getProperties(), input);
        input.addEventListener('keydown', function () {
            elementRemoveStateOnDownEventHandler(_this.getProperties(), element, FORM_TEXT_INPUT_CLASSNAME);
        });
        if (this.properties.business.onClickSystemIcon && !this.properties.optDisabled && this.systemIconContainerElement) {
            this.systemIconContainerElement.addEventListener('click', function (event) {
                _this.properties.business.onClickSystemIcon(event, input.value);
            });
        }
        // Format text with given format type
        if (this.properties.optAutoFormatType === FORM_TEXT_INPUT_MOBILE_NUMBER) {
            input.addEventListener('keyup', this.handleAutoFormatMobileNumber);
        }
    };
    /**
     * Intentionally return same props because no props to get defaults
     */
    FormTextInput.prototype.getDefaultProperties = function (newProperties) {
        return newProperties;
    };
    /**
     * Get props from DOM
     */
    FormTextInput.prototype.readDom = function (business) {
        this.initDomElements();
        var element = this.containerElement.getElementsByClassName(FORM_TEXT_INPUT_CLASSNAME)[0];
        var input = element.getElementsByClassName(FORM_TEXT_INPUT_INPUT_CLASSNAME)[0];
        var propertiesFromClassName = getPropertiesFromClassName(element.className, {
            optState: FORM_ELEMENT_STATES,
            optAutoFormatType: [FORM_TEXT_INPUT_MOBILE_NUMBER],
        });
        var optState = propertiesFromClassName.optState;
        var optAutoFormatType = propertiesFromClassName.optAutoFormatType;
        var autoComplete = input.getAttribute('autocomplete');
        var optAutoCompleteOff = (autoComplete === null) ? undefined : true;
        var id = input.id, name = input.name, type = input.type, value = input.value, placeholder = input.placeholder;
        var optDisabled = element.classList.contains(FORM_TEXT_INPUT_CLASSNAME_DISABLED);
        var stdPlaceholder = placeholder.length > 0 ? placeholder.trim() : undefined;
        var optRole = input.getAttribute('role') || undefined;
        var stdAriaControls = input.getAttribute('aria-controls') || undefined;
        var stdAriaAutocomplete = input.getAttribute('aria-autocomplete') || undefined;
        // @TODO typecheck instead of typecast
        var optType = type;
        var properties = {
            stdId: id,
            stdName: name,
            stdValue: value,
            stdPlaceholder: stdPlaceholder,
            optRole: optRole,
            stdAriaControls: stdAriaControls,
            stdAriaAutocomplete: stdAriaAutocomplete,
            optType: optType,
            optDisabled: optDisabled,
            optState: optState,
            optAutoCompleteOff: optAutoCompleteOff,
            optAutoFormatType: optAutoFormatType,
            business: business,
        };
        return removeUndefinedFieldsFromObject(properties);
    };
    /**
     * Update CSS classes based on the component's `optState` property
     */
    FormTextInput.prototype.handleUpdateState = function (optState) {
        var _a;
        var stateClasses = {
            error: FORM_TEXT_INPUT_CLASSNAME_ERROR,
            warn: FORM_TEXT_INPUT_CLASSNAME_WARN,
            success: FORM_TEXT_INPUT_CLASSNAME_SUCCESS,
        };
        var baseElement = this.containerElement.getElementsByClassName(FORM_TEXT_INPUT_CLASSNAME)[0];
        (_a = baseElement.classList).remove.apply(_a, __spreadArray([], __read(Object.values(stateClasses)), false));
        if (optState) {
            baseElement.classList.add(stateClasses[optState]);
        }
        this.handleUpdateNotificationIcon(optState);
    };
    /**
     * Update the system icon based on the component's `optSystemIcon` property
     */
    FormTextInput.prototype.handleUpdateSystemIcon = function (optSystemIcon) {
        var element = this.containerElement.getElementsByClassName(FORM_TEXT_INPUT_CLASSNAME)[0];
        element.classList.toggle(FORM_TEXT_INPUT_CLASSNAME_WITH_SYSTEM_ICON, !!optSystemIcon);
        this.systemIconComponent.update({ stdName: optSystemIcon }, false);
    };
    /**
     * Update the notification icon based on the component's `optState` property
     */
    FormTextInput.prototype.handleUpdateNotificationIcon = function (optState) {
        this.notificationIconComponent.update({ optState: optState }, false);
    };
    FormTextInput.prototype.didReceiveProps = function (newProps, oldProperties) {
        var optState = newProps.optState, optSystemIcon = newProps.optSystemIcon;
        if (oldProperties.optState !== optState) {
            this.handleUpdateState(optState);
        }
        if (oldProperties.optSystemIcon !== optSystemIcon) {
            this.handleUpdateSystemIcon(optSystemIcon);
        }
    };
    return FormTextInput;
}(Pattern));
export { FormTextInput };
/**
 * All components should have a factory function to create the component
 */
export var createFormTextInput = function (containerElement, properties) {
    var formTextInput = new FormTextInput(containerElement, properties);
    formTextInput.init();
    return formTextInput;
};
