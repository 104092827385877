var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __values = (this && this.__values) || function(o) {
    var s = typeof Symbol === "function" && Symbol.iterator, m = s && o[s], i = 0;
    if (m) return m.call(o);
    if (o && typeof o.length === "number") return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
    throw new TypeError(s ? "Object is not iterable." : "Symbol.iterator is not defined.");
};
import renderTemplate from './_unordered-horizontal-list.hbs';
import './unordered-horizontal-list.scss';
import { Pattern, removeUndefinedFieldsFromObject, } from '@vfde-brix/ws10/core';
import { UNORDERED_HORIZONTAL_LIST_INVERSE_CLASSNAME, UNORDERED_HORIZONTAL_LIST_BASE_CLASSNAME, UNORDERED_HORIZONTAL_LIST_BREAK_AFTER_HEADLINE_CLASSNAME, UNORDERED_HORIZONTAL_LIST_HEADLINE_CLASSNAME, UNORDERED_HORIZONTAL_LIST_ITEM, } from './Constants';
/**
 * UnorderedHorizontalList
 */
var UnorderedHorizontalList = /** @class */ (function (_super) {
    __extends(UnorderedHorizontalList, _super);
    function UnorderedHorizontalList() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     * Intentionally return same props. Because no default props to set, but abstract needs implementation
     */
    UnorderedHorizontalList.prototype.getDefaultProperties = function (newProperties) {
        return newProperties;
    };
    /**
     * Render this component to the DOM
     */
    UnorderedHorizontalList.prototype.writeDom = function () {
        this.containerElement.innerHTML = renderTemplate(this.properties);
    };
    /**
     * Init the events
     */
    UnorderedHorizontalList.prototype.initEvents = function () {
        var _a;
        var itemElement = this.containerElement.getElementsByTagName('li');
        for (var i = 0; i < this.properties.items.length; i++) {
            if ((_a = this.properties.items[i].link) === null || _a === void 0 ? void 0 : _a.onClick) {
                itemElement[i].addEventListener('click', this.properties.items[i].link.onClick);
            }
        }
    };
    /**
     * getPropertiesFromDOM
     * @todo Decide is that needed
     */
    UnorderedHorizontalList.prototype.readDom = function (unorderedHorizontalBusinessLogic) {
        var e_1, _a;
        var unorderedHorizontalList = this.containerElement.getElementsByClassName(UNORDERED_HORIZONTAL_LIST_BASE_CLASSNAME)[0];
        var headline = unorderedHorizontalList.getElementsByClassName(UNORDERED_HORIZONTAL_LIST_HEADLINE_CLASSNAME)[0];
        var listItemElements = unorderedHorizontalList.getElementsByClassName(UNORDERED_HORIZONTAL_LIST_ITEM);
        var listItems = [];
        var optBreakAfterHeadline = unorderedHorizontalList.classList.contains(UNORDERED_HORIZONTAL_LIST_BREAK_AFTER_HEADLINE_CLASSNAME) || undefined;
        var optInverse = unorderedHorizontalList.classList.contains(UNORDERED_HORIZONTAL_LIST_INVERSE_CLASSNAME) || undefined;
        try {
            for (var listItemElements_1 = __values(listItemElements), listItemElements_1_1 = listItemElements_1.next(); !listItemElements_1_1.done; listItemElements_1_1 = listItemElements_1.next()) {
                var listItemElement = listItemElements_1_1.value;
                var listItem = {
                    stdLabel: listItemElement.textContent.trim(),
                };
                var link = listItemElement.getElementsByTagName('a')[0];
                if (link) {
                    var listItemLink = {
                        stdLinkUrl: link.getAttribute('href'),
                    };
                    listItem.link = listItemLink;
                    if (link.target) {
                        listItemLink.optLinkTarget = link.target;
                    }
                    if (link.dataset.action) {
                        listItem.optAction = link.dataset.action;
                    }
                    if (link.dataset.trackingName) {
                        listItem.stdTrackingName = link.dataset.trackingName;
                    }
                }
                if (listItemElement.dataset.identifier) {
                    listItem.stdIdentifier = listItemElement.dataset.identifier;
                }
                listItems.push(listItem);
            }
        }
        catch (e_1_1) { e_1 = { error: e_1_1 }; }
        finally {
            try {
                if (listItemElements_1_1 && !listItemElements_1_1.done && (_a = listItemElements_1.return)) _a.call(listItemElements_1);
            }
            finally { if (e_1) throw e_1.error; }
        }
        return (removeUndefinedFieldsFromObject({
            items: listItems,
            txtHeadline: headline.innerHTML.trim(),
            optInverse: optInverse,
            optBreakAfterHeadline: optBreakAfterHeadline,
            business: unorderedHorizontalBusinessLogic,
        }));
    };
    return UnorderedHorizontalList;
}(Pattern));
export { UnorderedHorizontalList };
/**
 * This function returns an instance of UnorderedHorizontalList
 */
export var createUnorderedHorizontalList = function (containerElement, businessLogicOrProperties) {
    var unorderedHorizontalList = new UnorderedHorizontalList(containerElement, businessLogicOrProperties);
    unorderedHorizontalList.init();
    return unorderedHorizontalList;
};
