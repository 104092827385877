var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
var __values = (this && this.__values) || function(o) {
    var s = typeof Symbol === "function" && Symbol.iterator, m = s && o[s], i = 0;
    if (m) return m.call(o);
    if (o && typeof o.length === "number") return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
    throw new TypeError(s ? "Object is not iterable." : "Symbol.iterator is not defined.");
};
// import styles
import './accordion.scss';
import renderTemplate from './_accordion.hbs';
import { getPropertiesFromClassName, Pattern, removeUndefinedFieldsFromObject, } from '@vfde-brix/ws10/core';
import { ACCORDION_BASE_CLASSNAME, ACCORDION_ITEM_BASE_CLASSNAME, ACCORDION_ITEM_CHECKBOX, ACCORDION_ITEM_HEADLINE_CLASSNAME, ACCORDION_JUMP_TO_CLICKED_ACCORDION, ACCORDION_OPEN_INDIVIDUALLY_JS_CLASSNAME, ACCORDION_CHECKBOX, OPENED, OPEN_KEY, AccordionVariant, ACCORDION_ITEM_CONTENT_ID, TIMEOUT_VALUE, ACCORDION_ITEM_CARD, ACCORDION_ITEM_FLAT, ACCORDION_ITEM_CONTENT_BOX_CLASSNAME, ACCORDION_ITEM_CONTENT_CLASSNAME, ACCORDION_ITEM_ICON_CLASSNAME, } from './Constants';
import { AccordionParamService } from './AccordionParamService';
import { getSystemIconName } from '../system-icon/utils/getSystemIconName';
import { CLASSNAME_HIDDEN } from '@vfde-brix/ws10/styles';
/**
 * Accordion component class
 * This component is a 'Container' component.
 * Dynamic: It is rendered first and then other components can be rendered into it
 * Static: It all comes rendered, but this component is not responsible for calling its children readDOM functions
 */
var Accordion = /** @class */ (function (_super) {
    __extends(Accordion, _super);
    function Accordion() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        /**
         * accordionParamService
         * an instance from AccordionParamService
         */
        _this.accordionParamService = new AccordionParamService();
        /**
         * Open item by id
         */
        _this.openItemById = function (itemId) {
            var itemInputElement = _this.containerElement.querySelector("#".concat(ACCORDION_CHECKBOX).concat(itemId));
            if (itemInputElement && !itemInputElement.checked) {
                itemInputElement.click();
            }
        };
        /**
         * Handle the expansion of the accordion
         */
        _this.handleOnToggle = function (clickedIndex) {
            var accordionItems = _this.containerElement.getElementsByClassName(ACCORDION_ITEM_CHECKBOX);
            var _a = _this.properties, items = _a.items, optOpenIndividually = _a.optOpenIndividually, business = _a.business;
            var clickedItem = items[clickedIndex];
            var isOpen = !clickedItem.optOpen;
            for (var i = 0, iLen = items.length; i < iLen; i++) {
                var item = items[i];
                items[i].inputElement = accordionItems[i];
                if (optOpenIndividually) {
                    // if items are only allowed to open invididually, close all items and toggle the clicked item
                    var isClickedItemIndex = i === clickedIndex;
                    item.optOpen = isClickedItemIndex ? !item.optOpen : false;
                    item.inputElement.checked = item.optOpen;
                    _this.setHeightToContent(item);
                }
            }
            if (!optOpenIndividually) {
                // if items are allowed to open in parallel, only toggle the clicked item
                clickedItem.optOpen = isOpen;
                clickedItem.inputElement.checked = clickedItem.optOpen;
                _this.setHeightToContent(clickedItem);
            }
            // change aria expanded according to expanded or collapsed
            if (clickedItem.optOpen) {
                clickedItem.inputElement.parentElement.parentElement.setAttribute('aria-expanded', 'true');
                clickedItem.inputElement.parentElement.getElementsByClassName(ACCORDION_ITEM_CONTENT_CLASSNAME)[0]
                    .setAttribute('aria-hidden', 'false');
            }
            else {
                clickedItem.inputElement.parentElement.parentElement.setAttribute('aria-expanded', 'false');
                clickedItem.inputElement.parentElement.getElementsByClassName(ACCORDION_ITEM_CONTENT_CLASSNAME)[0]
                    .setAttribute('aria-hidden', 'true');
            }
            if (business.onToggle) {
                business.onToggle(clickedItem);
            }
            if (_this.properties && _this.properties.optDeepLink) {
                _this.accordionParamService.toggleUrlParam(clickedItem.stdId, isOpen);
            }
        };
        return _this;
    }
    /**
     * afterInit (called once after init)
     */
    Accordion.prototype.afterInit = function () {
        var accordionList = this.getProperties();
        if (accordionList && accordionList.optDeepLink) {
            var accordionIndex = this.accordionParamService.getAccordionFromParam(accordionList);
            if (accordionIndex !== undefined) {
                this.handleOnToggle(accordionIndex);
                this.openItemById(accordionList.items[accordionIndex].stdId);
                this.jumpToAccordion(accordionIndex, accordionList.items[accordionIndex].inputElement);
            }
        }
    };
    /**
     * Intentionally return same props. Because no default props to set, but abstract needs implementation
     */
    Accordion.prototype.getDefaultProperties = function (newProperties) {
        return newProperties;
    };
    /**
     * All rendering gets done with this function
     */
    Accordion.prototype.writeDom = function () {
        this.containerElement.innerHTML = renderTemplate(this.properties);
    };
    /**
     * This is an abstract function that every component needs to add event listeners to DOM elements
     */
    Accordion.prototype.initEvents = function () {
        var _this = this;
        var items = this.properties.items;
        this.handleVariant();
        var accordionItems = this.containerElement.getElementsByClassName(ACCORDION_ITEM_CHECKBOX);
        var l = accordionItems.length;
        var _loop_1 = function (i) {
            this_1.setHeightToContent(items[i]);
            accordionItems[i].addEventListener('click', function (event) {
                _this.jumpToClickedItem(i, event.currentTarget);
            });
            accordionItems[i].addEventListener('change', function () {
                _this.handleOnToggle(i);
            });
            accordionItems[i].parentElement.parentElement.addEventListener('keydown', function (event) {
                // toggle item expansion with Enter key
                if (event && event.key === OPEN_KEY.ENTER) {
                    _this.handleOnToggle(i);
                }
            });
        };
        var this_1 = this;
        for (var i = 0; i < l; i++) {
            _loop_1(i);
        }
    };
    /**
     * jumpToClickedItem
     */
    Accordion.prototype.jumpToClickedItem = function (clickedIndex, inputElement, isFromParam) {
        if (isFromParam === void 0) { isFromParam = false; }
        var _a = this.properties, items = _a.items, optJumpToClickedItem = _a.optJumpToClickedItem;
        var openItemIndex = this.getOpenItemIndex();
        var shouldRepairScrollPosition = false;
        if (this.properties.optOpenIndividually && openItemIndex !== -1 && clickedIndex > openItemIndex) {
            // The user opened a following accordion item after he opened a preceding item already
            // (e. g. first he opens item 1, then item 2).
            // This will make item1 close and item2 open, but if item1 has a larger content than item2,
            // this will quickly shrink the height of the page which catapults the user to the area below the accordion.
            // To avoid this behavior, we scroll the user to the opened item.
            // We don't need to do this, if the user opened a preceding item,
            // because in that case the page will just shrink,
            // until the point where it reaches the closed item (below the opened item).
            var precedingItemContentHeight = this.getContentHeight(this.properties.items[openItemIndex]);
            var followingItemContentHeight = this.getContentHeight(this.properties.items[clickedIndex]);
            shouldRepairScrollPosition = precedingItemContentHeight > followingItemContentHeight;
        }
        if (shouldRepairScrollPosition || (!items[clickedIndex].optOpen && optJumpToClickedItem) || isFromParam) {
            // Timeout to wait until animation of accordion is finished otherwise height is calculated wrong
            setTimeout(function () {
                inputElement.parentElement.scrollIntoView({ block: 'start', behavior: 'smooth' });
            }, TIMEOUT_VALUE);
        }
    };
    /**
     * Returns the index of the currently open item
     */
    Accordion.prototype.getOpenItemIndex = function () {
        return this.properties.items.findIndex(function (item) { return item.optOpen; });
    };
    /**
     * jumpToAccordion
     */
    Accordion.prototype.jumpToAccordion = function (accordionIndex, element) {
        var _this = this;
        window.addEventListener('load', function () {
            _this.jumpToClickedItem(accordionIndex, element, true);
        });
    };
    /**
     * allow to set max height if there are external changes
     * to control the animation
     */
    Accordion.prototype.setHeightToContent = function (item) {
        var itemId = item.stdId;
        var content = this.containerElement.querySelector("#".concat(ACCORDION_ITEM_CONTENT_ID).concat(itemId));
        var contentBox = content && content.firstElementChild;
        if (!item.optOpen && content) {
            content.style.height = '0px';
        }
        else if (contentBox && contentBox.scrollHeight > 0 && content) {
            content.style.height = "".concat(contentBox.scrollHeight, "px");
        }
    };
    /**
     * Returns the height of the content for the given item
     */
    Accordion.prototype.getContentHeight = function (item) {
        var itemId = item.stdId;
        var content = this.containerElement.querySelector("#".concat(ACCORDION_ITEM_CONTENT_ID).concat(itemId));
        var contentBox = content && content.firstElementChild;
        return (contentBox === null || contentBox === void 0 ? void 0 : contentBox.scrollHeight) || 0;
    };
    /**
     * handle variant Accordion
     */
    Accordion.prototype.handleVariant = function () {
        var optVariant = this.properties.optVariant;
        var accordionItems = this.containerElement.getElementsByClassName(ACCORDION_ITEM_BASE_CLASSNAME);
        var l = accordionItems.length;
        for (var i = 0; i < l; i++) {
            switch (optVariant) {
                case AccordionVariant.Card:
                    accordionItems[i].classList.add(ACCORDION_ITEM_CARD);
                    break;
                case AccordionVariant.Flat:
                    accordionItems[i].classList.add(ACCORDION_ITEM_FLAT);
                    break;
            }
        }
    };
    /**
     * getting properties from DOM
     */
    Accordion.prototype.readDom = function (accordionBusinessLogic) {
        var e_1, _a;
        var _b, _c;
        var accordionContainer = this.containerElement
            .getElementsByClassName(ACCORDION_BASE_CLASSNAME)[0];
        var accordionItems = __spreadArray([], __read(this.containerElement.getElementsByClassName(ACCORDION_ITEM_BASE_CLASSNAME)), false);
        var optOpenIndividually = accordionContainer.className
            .indexOf(ACCORDION_OPEN_INDIVIDUALLY_JS_CLASSNAME) !== -1;
        var optJumpToClickedItem = accordionContainer.className
            .indexOf(ACCORDION_JUMP_TO_CLICKED_ACCORDION) !== -1;
        var optDeepLink = accordionContainer
            .getAttribute('data-accordion-deeplink') === 'true';
        var optVariant = accordionContainer.getAttribute('data-accordion-variant');
        var items = [];
        try {
            for (var accordionItems_1 = __values(accordionItems), accordionItems_1_1 = accordionItems_1.next(); !accordionItems_1_1.done; accordionItems_1_1 = accordionItems_1.next()) {
                var accordionItem = accordionItems_1_1.value;
                // collect accordionItem props: start
                var stdHeadline = (_b = accordionItem.getElementsByClassName(ACCORDION_ITEM_HEADLINE_CLASSNAME)[0]) === null || _b === void 0 ? void 0 : _b.innerHTML.trim();
                var inputElement = accordionItem
                    .getElementsByTagName('input')[0];
                var stdId = inputElement.id.replace(ACCORDION_CHECKBOX, '');
                var containerAnyComponent = (_c = accordionItem.getElementsByClassName(ACCORDION_ITEM_CONTENT_BOX_CLASSNAME)[0]) === null || _c === void 0 ? void 0 : _c.innerHTML.trim();
                var propsFromClassName = getPropertiesFromClassName(accordionItem.className, {
                    openedString: ['opened'],
                    optVariant: Object.values(AccordionVariant),
                });
                var optOpen = propsFromClassName.openedString === OPENED;
                var optHidden = accordionItem.parentElement.classList.contains(CLASSNAME_HIDDEN);
                var iconContainer = accordionItem.getElementsByClassName(ACCORDION_ITEM_ICON_CLASSNAME)[0];
                var stdIconLeft = iconContainer ? getSystemIconName(iconContainer) : undefined;
                // collect accordionItem props: end
                var item = {
                    stdId: stdId,
                    stdHeadline: stdHeadline,
                    containerAnyComponent: containerAnyComponent,
                    stdIconLeft: stdIconLeft,
                    optOpen: optOpen,
                    optHidden: optHidden,
                    inputElement: inputElement,
                };
                if (item.optOpen) {
                    this.setHeightToContent(item);
                }
                items.push(removeUndefinedFieldsFromObject(item));
            }
        }
        catch (e_1_1) { e_1 = { error: e_1_1 }; }
        finally {
            try {
                if (accordionItems_1_1 && !accordionItems_1_1.done && (_a = accordionItems_1.return)) _a.call(accordionItems_1);
            }
            finally { if (e_1) throw e_1.error; }
        }
        return removeUndefinedFieldsFromObject({
            items: items,
            optOpenIndividually: optOpenIndividually,
            optJumpToClickedItem: optJumpToClickedItem,
            optDeepLink: optDeepLink,
            optVariant: optVariant,
            business: accordionBusinessLogic,
        });
    };
    return Accordion;
}(Pattern));
export { Accordion };
/**
 * This function returns an instance of Accordion
 */
export var createAccordion = function (containerElement, businessLogicOrProperties) {
    var accordion = new Accordion(containerElement, businessLogicOrProperties);
    accordion.init();
    return accordion;
};
