var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
var __values = (this && this.__values) || function(o) {
    var s = typeof Symbol === "function" && Symbol.iterator, m = s && o[s], i = 0;
    if (m) return m.call(o);
    if (o && typeof o.length === "number") return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
    throw new TypeError(s ? "Object is not iterable." : "Symbol.iterator is not defined.");
};
import renderTemplate from './_filter-pill.hbs';
import './filter-pill.scss';
import { getPropertiesFromClassName, Pattern, removeUndefinedFieldsFromObject, HandlebarRenderer, isMinTabletViewport, } from '@vfde-brix/ws10/core';
import { CLASSNAME_HIDDEN } from '@vfde-brix/ws10/styles';
import { FILTER_PILLS_ITEM_CLASSNAME, FILTER_PILLS_ITEM_SELECTED_CLASSNAME, FILTER_PILLS_TOGGLE_MORE_CLASSNAME, FILTER_PILLS_ITEM_TOGGLE_LESS_CLASSNAME, FILTER_PILLS_BASE_CLASSNAME, ITEM_CONTAINER_ALIGN_VALUES, ITEM_STATE_VALUES, FILTER_PILLS_ITEM_ICON_CLASSNAME, FILTER_PILLS_ITEM_DISABLED_CLASSNAME, FILTER_PILLS_OPEN_CLASSNAME, } from './Constants';
import { getSystemIconName } from '../system-icon';
/**
 * containerHeight for one or two lines of pills
 */
export var containerHeight = { oneLine: 50, twoLine: 110 };
/**
 * Filter pill component
 */
var FilterPill = /** @class */ (function (_super) {
    __extends(FilterPill, _super);
    function FilterPill() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        /**
         * Handle click
         */
        _this.handleOnClick = function (event) {
            var selectedItem = event.target;
            var moreButton = _this.containerElement.getElementsByClassName(FILTER_PILLS_TOGGLE_MORE_CLASSNAME)[0];
            event.preventDefault();
            // get the button even if icon or label was the target
            var buttonPill = selectedItem.closest(".".concat(FILTER_PILLS_ITEM_CLASSNAME));
            var buttonMore = selectedItem.closest(".".concat(FILTER_PILLS_TOGGLE_MORE_CLASSNAME));
            var buttonLess = selectedItem.closest(".".concat(FILTER_PILLS_ITEM_TOGGLE_LESS_CLASSNAME));
            // handle click on a pill
            if (buttonPill && !buttonPill.classList.contains(FILTER_PILLS_ITEM_DISABLED_CLASSNAME)) {
                buttonPill.classList.toggle(FILTER_PILLS_ITEM_SELECTED_CLASSNAME);
                buttonPill.setAttribute('aria-checked', String(buttonPill.classList.contains(FILTER_PILLS_ITEM_SELECTED_CLASSNAME)));
                var value = buttonPill.dataset.value;
                var business = _this.properties.business;
                if (business.onClick) {
                    business.onClick(value, event);
                }
            }
            // handle show more button
            if (buttonMore) {
                _this.toggleOpen(true);
            }
            // handle show less button
            if (buttonLess) {
                _this.toggleOpen(false);
                moreButton.focus();
            }
        };
        return _this;
    }
    /**
     * Intentionally return same props. Because no default props to set, but abstract needs implementation
     */
    FilterPill.prototype.getDefaultProperties = function (newProperties) {
        return newProperties;
    };
    /**
     * All rendering gets done with this function. If the component contains another
     * component, you would then render the child component in this function.
     */
    FilterPill.prototype.writeDom = function () {
        HandlebarRenderer.render(this.containerElement, renderTemplate(this.properties));
    };
    /**
     * toggle display more/less button
     */
    FilterPill.prototype.toggleOpen = function (value) {
        var filterPillElement = this.containerElement.getElementsByClassName(FILTER_PILLS_BASE_CLASSNAME)[0];
        filterPillElement.classList.toggle(FILTER_PILLS_OPEN_CLASSNAME, value);
        this.setPills();
    };
    /**
     * setPills
     * shows / hides items based on: the parent container height, compared to the desired hight in closed state.
     * @property {boolean} [init] - true if initial run.
     */
    FilterPill.prototype.setPills = function (init) {
        var e_1, _a;
        var elementToFocus = this.getElementToFocus();
        var filterPillElement = this.containerElement.getElementsByClassName(FILTER_PILLS_BASE_CLASSNAME)[0];
        var moreButton = this.containerElement.getElementsByClassName(FILTER_PILLS_TOGGLE_MORE_CLASSNAME)[0];
        var items = __spreadArray([], __read(this.containerElement.getElementsByClassName(FILTER_PILLS_ITEM_CLASSNAME)), false);
        // first we reset all the items to display flex, to be able to measure the containers height.
        if (!init) {
            try {
                for (var items_1 = __values(items), items_1_1 = items_1.next(); !items_1_1.done; items_1_1 = items_1.next()) {
                    var item = items_1_1.value;
                    item.classList.remove(CLASSNAME_HIDDEN);
                }
            }
            catch (e_1_1) { e_1 = { error: e_1_1 }; }
            finally {
                try {
                    if (items_1_1 && !items_1_1.done && (_a = items_1.return)) _a.call(items_1);
                }
                finally { if (e_1) throw e_1.error; }
            }
        }
        if (!this.isOpen() && filterPillElement.offsetHeight > this.getLineHeight()) {
            moreButton.classList.remove(CLASSNAME_HIDDEN);
            for (var i = items.length - 1; i >= 0; --i) {
                /* istanbul ignore else */
                if (filterPillElement.offsetHeight > this.getLineHeight()) {
                    items[i].classList.add(CLASSNAME_HIDDEN);
                }
            }
        }
        else {
            elementToFocus === null || elementToFocus === void 0 ? void 0 : elementToFocus.focus();
            moreButton.classList.add(CLASSNAME_HIDDEN);
        }
    };
    /**
     * get isOpen state
     */
    FilterPill.prototype.isOpen = function () {
        var filterPillElement = this.containerElement.getElementsByClassName(FILTER_PILLS_BASE_CLASSNAME)[0];
        return filterPillElement.classList.contains(FILTER_PILLS_OPEN_CLASSNAME);
    };
    /**
     * return the max height of the container in px.
     * on mobile view we show two lines in colapsed view, on desktop we show only one line
     */
    FilterPill.prototype.getLineHeight = function () {
        /* istanbul ignore next */
        return isMinTabletViewport() ? containerHeight.oneLine : containerHeight.twoLine;
    };
    FilterPill.prototype.getElementToFocus = function () {
        if (!this.isOpen()) {
            return;
        }
        return this.containerElement.getElementsByClassName(CLASSNAME_HIDDEN)[0];
    };
    /**
     * This is an abstract function that every component needs to add event listeners to DOM elements
     */
    FilterPill.prototype.initEvents = function (isInitial) {
        var _this = this;
        if (isInitial) {
            // Add event to the container and catch the element in the handler otherwise events are gone after an update
            this.containerElement.addEventListener('click', this.handleOnClick);
            // make it responsive to resize
            window.addEventListener('resize', function () { return _this.setPills(); });
        }
        // init
        this.setPills(true);
    };
    /**
     * Read dom
     */
    FilterPill.prototype.readDom = function (filterPillBusinessLogic) {
        var e_2, _a;
        var containerOptAlignmetSelector = {
            alignment: __spreadArray([], __read(ITEM_CONTAINER_ALIGN_VALUES), false),
        };
        var realContainer = this.containerElement.getElementsByClassName(FILTER_PILLS_BASE_CLASSNAME)[0];
        var containerAlignment = getPropertiesFromClassName(realContainer.className, containerOptAlignmetSelector).alignment;
        var properties = {
            listFilterPillItems: [],
            stdMoreLabel: this.containerElement.getElementsByClassName(FILTER_PILLS_TOGGLE_MORE_CLASSNAME)[0].textContent.trim(),
            stdLessLabel: this.containerElement.getElementsByClassName(FILTER_PILLS_ITEM_TOGGLE_LESS_CLASSNAME)[0].textContent.trim(),
            business: filterPillBusinessLogic,
        };
        properties.optAlignment = containerAlignment;
        var filterPillElements = __spreadArray([], __read(this.containerElement.getElementsByClassName(FILTER_PILLS_ITEM_CLASSNAME)), false);
        var stateSelector = {
            state: __spreadArray([], __read(ITEM_STATE_VALUES), false),
        };
        try {
            for (var filterPillElements_1 = __values(filterPillElements), filterPillElements_1_1 = filterPillElements_1.next(); !filterPillElements_1_1.done; filterPillElements_1_1 = filterPillElements_1.next()) {
                var filterPillElement = filterPillElements_1_1.value;
                var state = getPropertiesFromClassName(filterPillElement.className, stateSelector).state;
                var text = filterPillElement.textContent.trim();
                var value = filterPillElement.getAttribute('data-value');
                var icon = this.getIconName(filterPillElement);
                properties.listFilterPillItems.push(removeUndefinedFieldsFromObject({
                    stdPillLabel: text,
                    stdValue: value,
                    optState: state,
                    optIcon: icon,
                }));
            }
        }
        catch (e_2_1) { e_2 = { error: e_2_1 }; }
        finally {
            try {
                if (filterPillElements_1_1 && !filterPillElements_1_1.done && (_a = filterPillElements_1.return)) _a.call(filterPillElements_1);
            }
            finally { if (e_2) throw e_2.error; }
        }
        properties.business = filterPillBusinessLogic;
        return removeUndefinedFieldsFromObject(properties);
    };
    /**
     * Get Icon name from DOM
     */
    FilterPill.prototype.getIconName = function (filterPill) {
        var iconSvg = filterPill.getElementsByClassName(FILTER_PILLS_ITEM_ICON_CLASSNAME)[0];
        return getSystemIconName(iconSvg);
    };
    return FilterPill;
}(Pattern));
export { FilterPill };
/**
 * Filter pill factory
 */
export var createFilterPill = function (containerElement, businessLogicOrProperties) {
    var filterPill = new FilterPill(containerElement, businessLogicOrProperties);
    filterPill.init();
    return filterPill;
};
