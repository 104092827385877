import { createButton, } from '@vfde-brix/ws10/button';
import { createButtonLink, } from '@vfde-brix/ws10/button-link';
import { attempt, NO_PATTERN_BUSINESS_LOGIC, } from '@vfde-brix/ws10/core';
import { TARIFF_CARD_ACTION_BUTTON_CLASSNAME, TARIFF_CARD_BUTTON_LINK_CLASSNAME, } from './Constants';
/**
 * getButtonPropertiesFromDOM
 *
 * readDom helper function
 */
export var getButtonPropertiesFromDOM = function (containerElement) {
    var items = [];
    return attempt(function () {
        var buttonItems = containerElement.getElementsByClassName(TARIFF_CARD_ACTION_BUTTON_CLASSNAME);
        var buttonArray = Array.from(buttonItems);
        buttonArray.forEach(function (buttonAnchor) {
            var button = createButton(buttonAnchor, NO_PATTERN_BUSINESS_LOGIC);
            items.push(button.getProperties());
        });
        return items;
    });
};
/**
 * getButtonLinkPropertiesFromDOM
 *
 * readDom helper function
 */
export var getButtonLinkPropertiesFromDOM = function (containerElement) {
    var items = [];
    return attempt(function () {
        var buttonLinkItems = containerElement.getElementsByClassName(TARIFF_CARD_BUTTON_LINK_CLASSNAME);
        var buttonLinkArray = Array.from(buttonLinkItems);
        buttonLinkArray.forEach(function (buttonLinkAnchor) {
            var buttonLink = createButtonLink(buttonLinkAnchor, NO_PATTERN_BUSINESS_LOGIC);
            items.push(buttonLink.getProperties());
        });
        return items.length ? items : undefined;
    });
};
/**
 * handleClick
 *
 * button logic helper function
 */
export var handleClick = function (onClick, id) { return function () {
    onClick === null || onClick === void 0 ? void 0 : onClick(id);
}; };
