var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __values = (this && this.__values) || function(o) {
    var s = typeof Symbol === "function" && Symbol.iterator, m = s && o[s], i = 0;
    if (m) return m.call(o);
    if (o && typeof o.length === "number") return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
    throw new TypeError(s ? "Object is not iterable." : "Symbol.iterator is not defined.");
};
/* eslint-disable no-case-declarations */
import './fullwidth-notification.scss';
import renderTemplate from './_fullwidth-notification.hbs';
import { Pattern, removeUndefinedFieldsFromObject, getTransitionDurationFromElement, NO_PATTERN_BUSINESS_LOGIC, } from '@vfde-brix/ws10/core';
import { FULLWIDTH_NOTIFICATION_STATE_OPENED, FULLWIDTH_NOTIFICATION_STATE_CLOSED, FULLWIDTH_NOTIFICATION_TEXT_CONTENT_CLASSNAME, FULLWIDTH_NOTIFICATION_SUBTEXT_CONTENT_CLASSNAME, FULLWIDTH_NOTIFICATION_CLOSE_BUTTON_CONTAINER_CLASSNAME, FULLWIDTH_NOTIFICATION_POSITION_STICKY_TOP, FULLWIDTH_NOTIFICATION_POSITION_STICKY_BOTTOM, FULLWIDTH_NOTIFICATION_POSITION_ABOVE_FOOTER, FULLWIDTH_NOTIFICATION_POSITION_ABOVE_NAVBAR, FULLWIDTH_NOTIFICATION_POSITION_BELOW_NAVBAR, FULLWIDTH_NOTIFICATION_MAIN_PAGE_CONTENT_WRAPPER_ID, FULLWIDTH_NOTIFICATION_NAVBAR_WRAPPER_CLASSNAME, FULLWIDTH_NOTIFICATION_NAVBAR_CLASSNAME, FULLWIDTH_NOTIFICATION_TRANSITION_TOP_CLASSNAME, FULLWIDTH_NOTIFICATION_HEADER_TAGNAME, FULLWIDTH_NOTIFICATION_INLINE_TRANSITION_CSS_PROPERTY, FULLWIDTH_NOTIFICATION_WRAPPER_CLASSNAME, FULLWIDTH_NOTIFICATION_POSITION_CLASS_STICKY_TOP, FULLWIDTH_NOTIFICATION_POSITION_CLASS_ABOVE_NAVBAR, FULLWIDTH_NOTIFICATION_POSITION_CLASS_BELOW_NAVBAR, FULLWIDTH_NOTIFICATION_BASE_CLASSNAME, FULLWIDTH_NOTIFICATION_BUTTON_CLASSNAME, buttonProperties, FULLWIDTH_NOTIFICATION_COLOR_SECONDARY, FULLWIDTH_NOTIFICATION_COLOR_TERTIARY, closeButtonProperties, FULLWIDTH_NOTIFICATION_CLOSE_BUTTON_ARIA_LABEL, } from './Constants';
import { BUTTON_BASE_CLASSNAME, BUTTON_COLOR_PRIMARY, BUTTON_COLOR_SECONDARY, createButton, } from '../button';
import { getSystemIconName } from '../system-icon';
import { aboveNavbarHelper, belowNavbarHelper, footerHelper, getButtonIconOnlyPropertiesFromDOM, getColor, getContent, getPosition, getSessionStorageData, scrollHandler, setSessionStorageData, stickyBottomHelper, } from './helpers';
import { createButtonIconOnly, } from '../button-icon-only';
/**
 * The FullWidth Notification can be used to display a prominent message and related optional action.
 */
var FullWidthNotification = /** @class */ (function (_super) {
    __extends(FullWidthNotification, _super);
    function FullWidthNotification() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        // eslint-disable-next-line @typescript-eslint/naming-convention
        _this._state = FULLWIDTH_NOTIFICATION_STATE_OPENED;
        return _this;
    }
    /**
     * afterInit (called once after init)
     */
    FullWidthNotification.prototype.afterInit = function () {
        var properties = this.properties;
        var containerElement = this.containerElement.querySelector(".".concat(FULLWIDTH_NOTIFICATION_WRAPPER_CLASSNAME));
        var mainContainer = document.querySelector("#".concat(FULLWIDTH_NOTIFICATION_MAIN_PAGE_CONTENT_WRAPPER_ID));
        var navbarContainer = document.querySelector(".".concat(FULLWIDTH_NOTIFICATION_NAVBAR_CLASSNAME));
        var isPlacedByNavbar = (properties.optPosition === FULLWIDTH_NOTIFICATION_POSITION_BELOW_NAVBAR
            || properties.optPosition === FULLWIDTH_NOTIFICATION_POSITION_ABOVE_NAVBAR
            || properties.optPosition === FULLWIDTH_NOTIFICATION_POSITION_STICKY_TOP);
        /* istanbul ignore next */
        if (mainContainer && navbarContainer && properties.optPosition && !!properties.optPreview === false) {
            if (isPlacedByNavbar && !navbarContainer) {
                // eslint-disable-next-line no-console
                console.error('Positions "above-navbar" and "below-navbar" are unavailable without simplicity navbar.');
                return;
            }
            this.setupPositionObservers(containerElement, mainContainer, navbarContainer, properties.optPosition);
        }
        scrollHandler(properties.optPosition);
        this.resizeEventListenerHelper();
    };
    FullWidthNotification.prototype.getFullWidthNotificationElement = function () {
        return this.containerElement.querySelector(".".concat(FULLWIDTH_NOTIFICATION_BASE_CLASSNAME));
    };
    /* istanbul ignore next */
    /**
     * Utility function that should set up position observers.
     */
    FullWidthNotification.prototype.setupPositionObservers = function (containerElement, mainContainer, navbarContainer, optPosition) {
        var isPlacedByNavbar = (optPosition === FULLWIDTH_NOTIFICATION_POSITION_BELOW_NAVBAR
            || optPosition === FULLWIDTH_NOTIFICATION_POSITION_ABOVE_NAVBAR
            || optPosition === FULLWIDTH_NOTIFICATION_POSITION_STICKY_TOP);
        // Navbar is to be changed in the near future, will be refactored hopefully to a pure switch-case later on.
        if (isPlacedByNavbar) {
            var observerCallback = function (mutationsList) {
                var e_1, _a;
                try {
                    for (var mutationsList_1 = __values(mutationsList), mutationsList_1_1 = mutationsList_1.next(); !mutationsList_1_1.done; mutationsList_1_1 = mutationsList_1.next()) {
                        var mutation = mutationsList_1_1.value;
                        if (mutation.type === 'attributes' && mutation.attributeName === 'style') {
                            scrollHandler(optPosition);
                        }
                    }
                }
                catch (e_1_1) { e_1 = { error: e_1_1 }; }
                finally {
                    try {
                        if (mutationsList_1_1 && !mutationsList_1_1.done && (_a = mutationsList_1.return)) _a.call(mutationsList_1);
                    }
                    finally { if (e_1) throw e_1.error; }
                }
            };
            var config = { attributes: true, attributeFilter: ['style'] };
            this.observer = new MutationObserver(observerCallback);
            this.observer.observe(navbarContainer, config);
            mainContainer.style.marginTop = "".concat(containerElement.scrollHeight, "px");
            containerElement.style.position = 'fixed';
            containerElement.style.top = '0';
            switch (optPosition) {
                case FULLWIDTH_NOTIFICATION_POSITION_ABOVE_NAVBAR:
                    aboveNavbarHelper(mainContainer, containerElement);
                    break;
                case FULLWIDTH_NOTIFICATION_POSITION_BELOW_NAVBAR:
                    belowNavbarHelper(mainContainer, containerElement);
                    break;
                default:
                    break;
            }
        }
        else {
            switch (optPosition) {
                case FULLWIDTH_NOTIFICATION_POSITION_STICKY_BOTTOM:
                    stickyBottomHelper(mainContainer, containerElement);
                    break;
                case FULLWIDTH_NOTIFICATION_POSITION_ABOVE_FOOTER:
                    footerHelper(mainContainer, containerElement);
                    break;
                default:
                    break;
            }
        }
    };
    Object.defineProperty(FullWidthNotification.prototype, "state", {
        /**
         * Get the actual state of the fullwidth notification
         */
        get: function () {
            return this._state;
        },
        enumerable: false,
        configurable: true
    });
    /**
     * Intentionally return same props. Because no default props to set, but abstract needs implementation
     */
    FullWidthNotification.prototype.getDefaultProperties = function (newProperties) {
        return newProperties;
    };
    /**
     * All rendering gets done with this function. If the component contains another
     * component, you would then render the child component in this function.
     */
    FullWidthNotification.prototype.writeDom = function () {
        var shouldRender = getSessionStorageData(this.properties.stdFullwidthNotificationId);
        if (shouldRender) {
            this.containerElement.innerHTML = renderTemplate(this.properties);
            this.initButton(this.properties);
            this.initCloseButton();
        }
    };
    /**
     * Fill IFullWidthNotification when rendered by e.g. cms
     */
    FullWidthNotification.prototype.readDom = function (fullWidthNotificationBusinessLogic) {
        var containerElement = this.containerElement.querySelector(".".concat(FULLWIDTH_NOTIFICATION_WRAPPER_CLASSNAME));
        var shouldRender = getSessionStorageData(containerElement.getAttribute('data-id'));
        if (shouldRender) {
            var business = fullWidthNotificationBusinessLogic;
            var stdIconName = getSystemIconName(containerElement);
            var readTxtContent = getContent(containerElement.querySelector(".".concat(FULLWIDTH_NOTIFICATION_TEXT_CONTENT_CLASSNAME)));
            var readSubTextContent = getContent(containerElement.querySelector(".".concat(FULLWIDTH_NOTIFICATION_SUBTEXT_CONTENT_CLASSNAME)));
            var txtContent = readTxtContent ? readTxtContent.replace(/<\/?p>/gi, '') : undefined;
            var txtSubTextContent = readSubTextContent ? readSubTextContent.replace(/<\/?p>/gi, '') : undefined;
            var containerButtonIconOnly = getButtonIconOnlyPropertiesFromDOM(containerElement);
            var optCloseButton = Boolean(containerButtonIconOnly);
            var optColor = getColor(containerElement);
            var stdFullwidthNotificationId = containerElement.getAttribute('data-id');
            var optPosition = getPosition(containerElement);
            var properties = {
                business: business,
                optColor: optColor,
                txtContent: txtContent,
                optPosition: optPosition,
            };
            var stdButtonText = containerElement.getAttribute('data-cta-text');
            var linkButtonHref = containerElement.getAttribute('data-link-href');
            if (stdButtonText) {
                properties.stdButtonText = stdButtonText;
            }
            if (linkButtonHref) {
                properties.linkButtonHref = linkButtonHref;
            }
            this.initButton(properties);
            this.initCloseButton();
            return removeUndefinedFieldsFromObject({
                optCloseButton: optCloseButton,
                optColor: optColor,
                stdIconName: stdIconName,
                txtContent: txtContent,
                txtSubTextContent: txtSubTextContent,
                business: business,
                stdButtonText: stdButtonText,
                linkButtonHref: linkButtonHref,
                stdFullwidthNotificationId: stdFullwidthNotificationId,
                optPosition: optPosition,
            });
        }
        this.containerElement.remove();
        return {};
    };
    /**
     * This is an abstract function that every component needs to add event listeners to DOM elements
     */
    FullWidthNotification.prototype.resizeEventListenerHelper = function () {
        var properties = this.properties;
        var boundScrollHandler = scrollHandler.bind(this, properties.optPosition);
        window.addEventListener('resize', boundScrollHandler);
        this.boundScrollHandler = boundScrollHandler;
    };
    /**
     * This is an abstract function that every component needs to add event listeners to DOM elements
     */
    FullWidthNotification.prototype.initEvents = function () {
        var _a;
        var _b = this, properties = _b.properties, containerElement = _b.containerElement;
        if ((_a = properties.business) === null || _a === void 0 ? void 0 : _a.onButtonClick) {
            var buttonElement = containerElement.querySelector(".".concat(BUTTON_BASE_CLASSNAME));
            if (buttonElement !== null) {
                buttonElement.addEventListener('click', properties.business.onButtonClick);
            }
        }
        this.handleCloseButtonClick();
    };
    /**
     * Init button child component
     * @param properties The fullwidth notification properties
     */
    FullWidthNotification.prototype.initButton = function (properties) {
        var e_2, _a;
        var fullWidthNotificationElement = this.getFullWidthNotificationElement();
        var buttonContainers = fullWidthNotificationElement.querySelectorAll(".".concat(FULLWIDTH_NOTIFICATION_BUTTON_CLASSNAME));
        try {
            for (var buttonContainers_1 = __values(buttonContainers), buttonContainers_1_1 = buttonContainers_1.next(); !buttonContainers_1_1.done; buttonContainers_1_1 = buttonContainers_1.next()) {
                var buttonContainer = buttonContainers_1_1.value;
                var buttonText = this.getButtonTextFromDom(properties);
                var buttonHref = this.getButtonHrefFromDom(properties);
                // eslint-disable-next-line max-len
                var isButtonInverted = [FULLWIDTH_NOTIFICATION_COLOR_SECONDARY, FULLWIDTH_NOTIFICATION_COLOR_TERTIARY].indexOf(properties.optColor) >= 0;
                // eslint-disable-next-line max-len
                var buttonColor = ([FULLWIDTH_NOTIFICATION_COLOR_SECONDARY, FULLWIDTH_NOTIFICATION_COLOR_TERTIARY].indexOf(properties.optColor) >= 0) ? BUTTON_COLOR_SECONDARY : BUTTON_COLOR_PRIMARY;
                var buttonProps = Object.assign({
                    stdContent: buttonText,
                    stdAriaLabelledBy: buttonText,
                    optInverse: isButtonInverted,
                    optColor: buttonColor,
                    linkHref: buttonHref,
                }, buttonProperties);
                createButton(buttonContainer, buttonProps);
            }
        }
        catch (e_2_1) { e_2 = { error: e_2_1 }; }
        finally {
            try {
                if (buttonContainers_1_1 && !buttonContainers_1_1.done && (_a = buttonContainers_1.return)) _a.call(buttonContainers_1);
            }
            finally { if (e_2) throw e_2.error; }
        }
    };
    /**
     * Init close button child component
     */
    FullWidthNotification.prototype.initCloseButton = function () {
        var e_3, _a;
        var fullWidthNotificationElement = this.getFullWidthNotificationElement();
        var buttonIconOnlyContainers = fullWidthNotificationElement.querySelectorAll(".".concat(FULLWIDTH_NOTIFICATION_CLOSE_BUTTON_CONTAINER_CLASSNAME));
        try {
            for (var buttonIconOnlyContainers_1 = __values(buttonIconOnlyContainers), buttonIconOnlyContainers_1_1 = buttonIconOnlyContainers_1.next(); !buttonIconOnlyContainers_1_1.done; buttonIconOnlyContainers_1_1 = buttonIconOnlyContainers_1.next()) {
                var buttonIconOnlyContainer = buttonIconOnlyContainers_1_1.value;
                // eslint-disable-next-line max-len
                var buttonIconOnlyProps = Object.assign({
                    stdAriaLabel: FULLWIDTH_NOTIFICATION_CLOSE_BUTTON_ARIA_LABEL,
                    business: NO_PATTERN_BUSINESS_LOGIC,
                }, closeButtonProperties);
                createButtonIconOnly(buttonIconOnlyContainer, buttonIconOnlyProps);
            }
        }
        catch (e_3_1) { e_3 = { error: e_3_1 }; }
        finally {
            try {
                if (buttonIconOnlyContainers_1_1 && !buttonIconOnlyContainers_1_1.done && (_a = buttonIconOnlyContainers_1.return)) _a.call(buttonIconOnlyContainers_1);
            }
            finally { if (e_3) throw e_3.error; }
        }
    };
    /**
     * Get the correct button text depending on the status of the given fullwidth notification
     */
    FullWidthNotification.prototype.getButtonTextFromDom = function (properties) {
        return properties.stdButtonText;
    };
    /**
     * Get the correct button href depending on the status of the given fullwidth notification
     */
    FullWidthNotification.prototype.getButtonHrefFromDom = function (properties) {
        return properties.linkButtonHref;
    };
    /**
     * TypeScript function to toggle the "closed" class on the div
     */
    FullWidthNotification.prototype.handleCloseButtonClick = function () {
        var _this = this;
        var closeButton = this.containerElement.querySelector(".".concat(FULLWIDTH_NOTIFICATION_CLOSE_BUTTON_CONTAINER_CLASSNAME));
        var notificationDiv = this.containerElement.querySelector(".".concat(FULLWIDTH_NOTIFICATION_WRAPPER_CLASSNAME));
        if (closeButton && notificationDiv) {
            closeButton.addEventListener('click', function () {
                if (_this._state === FULLWIDTH_NOTIFICATION_STATE_OPENED) {
                    _this.close();
                }
            });
        }
    };
    /**
     * Closes the fullwidth notification by removing a class
     */
    FullWidthNotification.prototype.close = function () {
        var _a = this, properties = _a.properties, observer = _a.observer;
        var containerElement = this.containerElement.querySelector(".".concat(FULLWIDTH_NOTIFICATION_WRAPPER_CLASSNAME));
        containerElement.style.height = "".concat(containerElement.scrollHeight, "px");
        containerElement.classList.remove(FULLWIDTH_NOTIFICATION_STATE_OPENED);
        containerElement.classList.add(FULLWIDTH_NOTIFICATION_STATE_CLOSED);
        var transitionTime = getTransitionDurationFromElement(containerElement);
        var mainContainer = document.querySelector("#".concat(FULLWIDTH_NOTIFICATION_MAIN_PAGE_CONTENT_WRAPPER_ID));
        var navbarContainer = document.querySelector(".".concat(FULLWIDTH_NOTIFICATION_NAVBAR_CLASSNAME));
        navbarContainer && navbarContainer.classList.add(FULLWIDTH_NOTIFICATION_TRANSITION_TOP_CLASSNAME);
        mainContainer && mainContainer.classList.add(FULLWIDTH_NOTIFICATION_TRANSITION_TOP_CLASSNAME);
        if (mainContainer && navbarContainer && properties.optPosition && !properties.optPreview) {
            this.handlePositionOnClose(mainContainer, navbarContainer, containerElement, observer);
        }
        containerElement.style.height = '0';
        this._state = FULLWIDTH_NOTIFICATION_STATE_CLOSED;
        setSessionStorageData(false, properties.stdFullwidthNotificationId);
        setTimeout(function () {
            containerElement.remove();
            mainContainer && mainContainer.classList.remove(FULLWIDTH_NOTIFICATION_TRANSITION_TOP_CLASSNAME);
        }, transitionTime);
    };
    /* istanbul ignore next */
    FullWidthNotification.prototype.handlePositionOnClose = function (mainContainer, navbarContainer, containerElement, observer) {
        var optPosition = this.properties.optPosition;
        var navbarWrapperContainer = mainContainer.querySelector(".".concat(FULLWIDTH_NOTIFICATION_NAVBAR_WRAPPER_CLASSNAME));
        switch (optPosition) {
            case FULLWIDTH_NOTIFICATION_POSITION_BELOW_NAVBAR:
                var secondContainer = document.querySelector(".".concat(FULLWIDTH_NOTIFICATION_POSITION_CLASS_STICKY_TOP, ", .").concat(FULLWIDTH_NOTIFICATION_POSITION_CLASS_ABOVE_NAVBAR));
                var contentContainer = containerElement.parentElement.nextElementSibling;
                contentContainer.style.transition = "margin-top ".concat(FULLWIDTH_NOTIFICATION_INLINE_TRANSITION_CSS_PROPERTY);
                containerElement.style.top = "".concat((mainContainer.querySelector(FULLWIDTH_NOTIFICATION_HEADER_TAGNAME).clientHeight + (secondContainer ? secondContainer.clientHeight : 0)), "px");
                mainContainer.style.marginTop = secondContainer ? "".concat(containerElement.scrollHeight, "px") : '0';
                contentContainer.style.marginTop = '0';
                navbarWrapperContainer && navbarWrapperContainer.removeAttribute('style');
                break;
            case FULLWIDTH_NOTIFICATION_POSITION_STICKY_BOTTOM:
                mainContainer.classList.add(FULLWIDTH_NOTIFICATION_TRANSITION_TOP_CLASSNAME);
                mainContainer.style.marginBottom = '0';
                break;
            case FULLWIDTH_NOTIFICATION_POSITION_ABOVE_FOOTER:
                navbarContainer.style.marginBottom = '0';
                break;
            default:
                var belowNavbarContainer = document.querySelector(".".concat(FULLWIDTH_NOTIFICATION_POSITION_CLASS_BELOW_NAVBAR));
                navbarContainer.classList.remove(FULLWIDTH_NOTIFICATION_TRANSITION_TOP_CLASSNAME);
                if (belowNavbarContainer) {
                    mainContainer.classList.add(FULLWIDTH_NOTIFICATION_TRANSITION_TOP_CLASSNAME);
                    belowNavbarContainer.classList.add(FULLWIDTH_NOTIFICATION_TRANSITION_TOP_CLASSNAME);
                    belowNavbarContainer.style.top = "".concat(navbarWrapperContainer.scrollHeight, "px");
                }
                mainContainer.style.marginTop = '0';
                navbarContainer.style.top = '0';
                break;
        }
        if (observer) {
            observer.disconnect();
        }
        window.removeEventListener('resize', this.boundScrollHandler);
    };
    return FullWidthNotification;
}(Pattern));
export { FullWidthNotification };
/**
 * This function returns an instance of FullWidthNotification
 */
export var createFullWidthNotification = function (containerElement, properties) {
    var fullWidthNotification = new FullWidthNotification(containerElement, properties);
    fullWidthNotification.init();
    return fullWidthNotification;
};
