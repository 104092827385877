var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __values = (this && this.__values) || function(o) {
    var s = typeof Symbol === "function" && Symbol.iterator, m = s && o[s], i = 0;
    if (m) return m.call(o);
    if (o && typeof o.length === "number") return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
    throw new TypeError(s ? "Object is not iterable." : "Symbol.iterator is not defined.");
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import './hybrid-table.scss';
import renderTemplate from './_hybrid-table.hbs';
import { attempt, NO_PATTERN_BUSINESS_LOGIC, Pattern, } from '@vfde-brix/ws10/core';
import { HYBRID_TABLE_ACCORDION, HYBRID_TABLE_TOOLTIP, HYBRID_TABLE_DESKTOP_ROW, HYBRID_TABLE_HIDDEN, HYBRID_TABLE_ICON, HYBRID_TABLE_MOBILE_ROW, HYBRID_TABLE_PAGINATE, HYBRID_TABLE_PAGINATE_CHUNKS, HYBRID_PAGINATE_SHOW_LESS, HYBRID_TABLE_PAGINATE_SHOW_MORE, HYBRID_TABLE_SORT, HYBRID_TABLE_SORT_ASCENDING_MODIFIER, HYBRID_TABLE_SORT_DESCENDING_MODIFIER, HYBRID_TABLE_TEXT, HYBRID_TABLE_ICON_WRAPPER, HYBRID_TABLE_SORT_ASC, HYBRID_TABLE_SORT_DESC, } from './Constants';
import { createAccordion } from '@vfde-brix/ws10/accordion';
import { createTooltip } from '@vfde-brix/ws10/tooltip';
import { createHeadline, HEADLINE_BASE_CLASSNAME as headlineClassName, } from '@vfde-brix/ws10/headline';
import { createIconText, } from '@vfde-brix/ws10/icon-text';
/**
 * hybridTable
 */
var HybridTable = /** @class */ (function (_super) {
    __extends(HybridTable, _super);
    function HybridTable() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.paginateControl = undefined;
        _this.paginateCounter = 0;
        _this.isKeypressEventButNotEnter = function (event) { return (event.type === 'keypress' && event.key !== 'Enter'); };
        /**
         * handle onChange input field triggered by clicking on the items
         */
        _this.handleOnSort = function (event) {
            if (_this.isKeypressEventButNotEnter(event) === true) {
                return;
            }
            var sortingWrapper = event.currentTarget;
            var currentSortOrder = (sortingWrapper.classList.contains(HYBRID_TABLE_SORT_ASCENDING_MODIFIER))
                ? HYBRID_TABLE_SORT_ASC
                : HYBRID_TABLE_SORT_DESC;
            var requestSortOrder = (HYBRID_TABLE_SORT_ASC === currentSortOrder) ? HYBRID_TABLE_SORT_DESC : HYBRID_TABLE_SORT_ASC;
            sortingWrapper.classList.toggle(HYBRID_TABLE_SORT_ASCENDING_MODIFIER);
            sortingWrapper.classList.toggle(HYBRID_TABLE_SORT_DESCENDING_MODIFIER);
            _this.properties.business.requestSorting(requestSortOrder, event);
        };
        _this.handleIconActivated = function (event) {
            var input = event.currentTarget;
            _this.properties.business.iconActivated(input, event);
        };
        _this.handlePaginate = function (event) {
            if (_this.isKeypressEventButNotEnter(event) === true) {
                return;
            }
            if (_this.paginateControl.classList.contains(HYBRID_TABLE_PAGINATE_SHOW_MORE) === true) {
                _this.handlePaginateMore();
            }
            else {
                _this.handlePaginateLess();
            }
        };
        return _this;
    }
    /**
     * Intentionally return same props. Because no default props to set, but abstract needs implementation
     */
    HybridTable.prototype.getDefaultProperties = function (newProperties) {
        return newProperties;
    };
    /**
     * All rendering gets done with this function. If the component contains another
     * component, you would then render the child component in this function.
     */
    HybridTable.prototype.writeDom = function () {
        var e_1, _a;
        this.containerElement.innerHTML = renderTemplate(this.properties);
        var tooltipElements = this.containerElement.getElementsByClassName(HYBRID_TABLE_TOOLTIP);
        try {
            for (var tooltipElements_1 = __values(tooltipElements), tooltipElements_1_1 = tooltipElements_1.next(); !tooltipElements_1_1.done; tooltipElements_1_1 = tooltipElements_1.next()) {
                var tooltipElement = tooltipElements_1_1.value;
                createTooltip(tooltipElement, NO_PATTERN_BUSINESS_LOGIC);
            }
        }
        catch (e_1_1) { e_1 = { error: e_1_1 }; }
        finally {
            try {
                if (tooltipElements_1_1 && !tooltipElements_1_1.done && (_a = tooltipElements_1.return)) _a.call(tooltipElements_1);
            }
            finally { if (e_1) throw e_1.error; }
        }
    };
    /**
     * add event listeners to DOM elements
     */
    HybridTable.prototype.initEvents = function () {
        var e_2, _a, e_3, _b;
        var _this = this;
        var _c, _d, _e, _f, _g, _h, _j, _k;
        /** Initializing Rows */
        var tableBodyElements = (_c = this.containerElement) === null || _c === void 0 ? void 0 : _c.getElementsByTagName('tbody');
        var tableBody = (tableBodyElements === null || tableBodyElements === void 0 ? void 0 : tableBodyElements.length) ? tableBodyElements[0] : undefined;
        var tableDesktopRowElements = tableBody === null || tableBody === void 0 ? void 0 : tableBody.getElementsByClassName(HYBRID_TABLE_DESKTOP_ROW);
        var tableMobileRowElements = tableBody ? tableBody.getElementsByClassName(HYBRID_TABLE_MOBILE_ROW) : undefined;
        var tableHeadElements = (_d = this.containerElement) === null || _d === void 0 ? void 0 : _d.getElementsByTagName('thead');
        var tableHead = (tableHeadElements === null || tableHeadElements === void 0 ? void 0 : tableHeadElements.length) ? tableHeadElements[0] : undefined;
        var tableHeadRowElements = tableHead ? tableHead.getElementsByClassName(HYBRID_TABLE_DESKTOP_ROW) : undefined;
        var tableHeadrow = (tableHeadRowElements === null || tableHeadRowElements === void 0 ? void 0 : tableHeadRowElements.length) ? tableHeadRowElements[0] : undefined;
        var columnHeadline = tableHeadrow === null || tableHeadrow === void 0 ? void 0 : tableHeadrow.getElementsByTagName('th');
        this.desktopRows = (tableDesktopRowElements === null || tableDesktopRowElements === void 0 ? void 0 : tableDesktopRowElements.length) ? __spreadArray([], __read(tableDesktopRowElements), false) : undefined;
        this.mobileRows = (tableMobileRowElements === null || tableMobileRowElements === void 0 ? void 0 : tableMobileRowElements.length) ? __spreadArray([], __read(tableMobileRowElements), false) : undefined;
        var columnElements = columnHeadline ? __spreadArray([], __read(columnHeadline), false) : undefined;
        columnElements === null || columnElements === void 0 ? void 0 : columnElements.forEach(function (columnElement, index) {
            var columnHeadlineElements = columnElement.getElementsByClassName(headlineClassName);
            var headlineElement = (columnHeadlineElements === null || columnHeadlineElements === void 0 ? void 0 : columnHeadlineElements.length) ? columnHeadlineElements[0] : undefined;
            if (headlineElement) {
                headlineElement.lang = 'de';
                headlineElement.innerHTML = _this.properties.tableColumns[index].label.stdContent;
                headlineElement.style.display = 'inline-block';
            }
        });
        /** initialize show more and show  less */
        var paginationContorlElements = (_e = this.containerElement) === null || _e === void 0 ? void 0 : _e.getElementsByClassName(HYBRID_TABLE_PAGINATE);
        this.paginateControl = (paginationContorlElements === null || paginationContorlElements === void 0 ? void 0 : paginationContorlElements.length) ? paginationContorlElements[0] : undefined;
        /** desktop Rows == mobile rows in length */
        if (((_f = this.desktopRows) === null || _f === void 0 ? void 0 : _f.length) > HYBRID_TABLE_PAGINATE_CHUNKS) {
            this.paginateControl.classList.remove(HYBRID_TABLE_HIDDEN);
            this.paginateCounter = HYBRID_TABLE_PAGINATE_CHUNKS;
        }
        (_g = this.paginateControl) === null || _g === void 0 ? void 0 : _g.addEventListener('click', this.handlePaginate);
        (_h = this.paginateControl) === null || _h === void 0 ? void 0 : _h.addEventListener('keypress', this.handlePaginate);
        /** handle sorting icons and adding for each one an event listener */
        var sortingIconElements = (_j = this.containerElement) === null || _j === void 0 ? void 0 : _j.getElementsByClassName(HYBRID_TABLE_SORT);
        this.sortingIcons = (sortingIconElements === null || sortingIconElements === void 0 ? void 0 : sortingIconElements.length) ? __spreadArray([], __read(sortingIconElements), false) : undefined;
        if (this.sortingIcons) {
            try {
                for (var _l = __values(this.sortingIcons), _m = _l.next(); !_m.done; _m = _l.next()) {
                    var icon = _m.value;
                    icon.addEventListener('click', this.handleOnSort);
                    icon.addEventListener('keypress', this.handleOnSort);
                }
            }
            catch (e_2_1) { e_2 = { error: e_2_1 }; }
            finally {
                try {
                    if (_m && !_m.done && (_a = _l.return)) _a.call(_l);
                }
                finally { if (e_2) throw e_2.error; }
            }
        }
        /** handle  icons and adding for each one an event listener */
        var iconElements = (_k = this.containerElement) === null || _k === void 0 ? void 0 : _k.getElementsByClassName(HYBRID_TABLE_ICON);
        this.icons = (iconElements === null || iconElements === void 0 ? void 0 : iconElements.length) ? __spreadArray([], __read(iconElements), false) : undefined;
        if (this.icons) {
            try {
                for (var _o = __values(this.icons), _p = _o.next(); !_p.done; _p = _o.next()) {
                    var icon = _p.value;
                    icon.addEventListener('click', this.handleIconActivated);
                    icon.addEventListener('keypress', this.handleIconActivated);
                }
            }
            catch (e_3_1) { e_3 = { error: e_3_1 }; }
            finally {
                try {
                    if (_p && !_p.done && (_b = _o.return)) _b.call(_o);
                }
                finally { if (e_3) throw e_3.error; }
            }
        }
    };
    /**
     * read dom and intialize table properties.
     */
    HybridTable.prototype.readDom = function (tableBusinessLogic) {
        var _a;
        /** Initialize accordions */
        var accordionElements = (_a = this.containerElement) === null || _a === void 0 ? void 0 : _a.getElementsByClassName(HYBRID_TABLE_ACCORDION);
        var accordionElement = (accordionElements === null || accordionElements === void 0 ? void 0 : accordionElements.length) ? accordionElements[0] : undefined;
        accordionElement ? createAccordion(accordionElement, NO_PATTERN_BUSINESS_LOGIC) : undefined;
        var tableColumns = this.readTableColumns();
        var tableData = this.readTableData();
        return {
            tableColumns: tableColumns,
            tableData: tableData,
            business: tableBusinessLogic,
        };
    };
    /** read table columns data */
    HybridTable.prototype.readTableColumns = function () {
        var _a;
        var tableHeadElements = (_a = this.containerElement) === null || _a === void 0 ? void 0 : _a.getElementsByTagName('thead');
        var tableHead = (tableHeadElements === null || tableHeadElements === void 0 ? void 0 : tableHeadElements.length) ? tableHeadElements[0] : undefined;
        var tableDesktopRowElements = tableHead === null || tableHead === void 0 ? void 0 : tableHead.getElementsByClassName(HYBRID_TABLE_DESKTOP_ROW);
        var tableColumnsElements = (tableDesktopRowElements === null || tableDesktopRowElements === void 0 ? void 0 : tableDesktopRowElements.length) ? tableDesktopRowElements[0].getElementsByTagName('th') : undefined;
        var columnElements = tableColumnsElements ? __spreadArray([], __read(tableColumnsElements), false) : undefined;
        return columnElements === null || columnElements === void 0 ? void 0 : columnElements.map(function (columnElement) {
            var tableColumn = attempt(function () {
                var headline = createHeadline(columnElement, NO_PATTERN_BUSINESS_LOGIC);
                return {
                    label: headline.getProperties(),
                };
            }, { label: undefined });
            var tooltipElement = columnElement.getElementsByClassName(HYBRID_TABLE_TOOLTIP);
            if (tooltipElement === null || tooltipElement === void 0 ? void 0 : tooltipElement.length) {
                tableColumn.tooltip = attempt(function () {
                    var tooltip = createTooltip(columnElement, NO_PATTERN_BUSINESS_LOGIC);
                    return tooltip.getProperties();
                });
            }
            var sortingElement = columnElement.getElementsByClassName(HYBRID_TABLE_SORT);
            if (sortingElement === null || sortingElement === void 0 ? void 0 : sortingElement.length) {
                tableColumn.sort = (sortingElement[0].classList.contains(HYBRID_TABLE_SORT_ASCENDING_MODIFIER) === true)
                    ? HYBRID_TABLE_SORT_ASC
                    : HYBRID_TABLE_SORT_DESC;
            }
            return tableColumn;
        });
    };
    /** read table cells data */
    HybridTable.prototype.readTableData = function () {
        var _a;
        var tableBodyElements = (_a = this.containerElement) === null || _a === void 0 ? void 0 : _a.getElementsByTagName('tbody');
        var tableBody = (tableBodyElements === null || tableBodyElements === void 0 ? void 0 : tableBodyElements.length) ? tableBodyElements[0] : undefined;
        var desktoRowElements = tableBody ? tableBody.getElementsByClassName(HYBRID_TABLE_DESKTOP_ROW) : undefined;
        var desktopRows = desktoRowElements ? __spreadArray([], __read(desktoRowElements), false) : undefined;
        return desktopRows === null || desktopRows === void 0 ? void 0 : desktopRows.map(function (row) {
            var tdsElements = row.getElementsByTagName('td');
            var tds = (tdsElements === null || tdsElements === void 0 ? void 0 : tdsElements.length) ? __spreadArray([], __read(tdsElements), false) : undefined;
            var cells = tds === null || tds === void 0 ? void 0 : tds.map(function (td) {
                var tableText = td.getElementsByClassName(HYBRID_TABLE_TEXT);
                if (tableText === null || tableText === void 0 ? void 0 : tableText.length) {
                    var cell = {
                        tableText: tableText[0].innerHTML.trim(),
                    };
                    return cell;
                }
                var iconsElements = td.getElementsByClassName(HYBRID_TABLE_ICON);
                if (iconsElements === null || iconsElements === void 0 ? void 0 : iconsElements.length) {
                    var mappableIconsElements = __spreadArray([], __read(iconsElements), false);
                    var icons = mappableIconsElements === null || mappableIconsElements === void 0 ? void 0 : mappableIconsElements.map(function (mappableIconsElement) {
                        var _a;
                        var iconElements = mappableIconsElement.getElementsByTagName('use');
                        var iconUseElement = (iconElements === null || iconElements === void 0 ? void 0 : iconElements.length) ? iconElements[0] : undefined;
                        var imgIconSrc = (_a = iconUseElement === null || iconUseElement === void 0 ? void 0 : iconUseElement.getAttribute('xlink:href')) === null || _a === void 0 ? void 0 : _a.replace('#icon', '');
                        var icon = {
                            imgIconSrc: imgIconSrc,
                        };
                        return icon;
                    });
                    var cell = {
                        icons: icons,
                    };
                    return cell;
                }
                // each cell contains a  ITableText or ITableIcons or IIconTextProperties
                // at this point we already know that it can't be ITableText or ITableIcons
                var iconTextWrapper = td.getElementsByClassName(HYBRID_TABLE_ICON_WRAPPER);
                if (iconTextWrapper === null || iconTextWrapper === void 0 ? void 0 : iconTextWrapper.length) {
                    var iconText = createIconText(iconTextWrapper[0], NO_PATTERN_BUSINESS_LOGIC);
                    return iconText.getProperties();
                }
            });
            var item = {
                cells: cells,
            };
            return item;
        });
    };
    HybridTable.prototype.paginate = function () {
        var _a;
        // start @ TABLE_PAGINATE_CHUNKS, because less the this is never shown in paginate
        for (var i = HYBRID_TABLE_PAGINATE_CHUNKS, x = (_a = this.desktopRows) === null || _a === void 0 ? void 0 : _a.length; i < x; i += 1) {
            if (i < this.paginateCounter) {
                this.desktopRows[i].classList.remove(HYBRID_TABLE_HIDDEN);
                this.mobileRows[i].classList.remove(HYBRID_TABLE_HIDDEN);
            }
            else {
                this.desktopRows[i].classList.add(HYBRID_TABLE_HIDDEN);
                this.mobileRows[i].classList.add(HYBRID_TABLE_HIDDEN);
            }
        }
    };
    HybridTable.prototype.handlePaginateToggle = function () {
        var _a, _b;
        (_a = this.paginateControl) === null || _a === void 0 ? void 0 : _a.classList.toggle(HYBRID_TABLE_PAGINATE_SHOW_MORE);
        (_b = this.paginateControl) === null || _b === void 0 ? void 0 : _b.classList.toggle(HYBRID_PAGINATE_SHOW_LESS);
    };
    HybridTable.prototype.handlePaginateLess = function () {
        this.paginateCounter = HYBRID_TABLE_PAGINATE_CHUNKS;
        this.paginate();
        this.handlePaginateToggle();
    };
    HybridTable.prototype.handlePaginateMore = function () {
        var _a;
        this.paginateCounter += HYBRID_TABLE_PAGINATE_CHUNKS;
        this.paginate();
        if (this.paginateCounter >= ((_a = this.properties.tableData) === null || _a === void 0 ? void 0 : _a.length)) {
            this.handlePaginateToggle();
        }
    };
    return HybridTable;
}(Pattern));
export { HybridTable };
/**
 * factory function
 * This function returns an instance of hybrid Table
 */
export var createHybridTable = function (containerElement, businessLogicOrProperties) {
    var hybridTable = new HybridTable(containerElement, businessLogicOrProperties);
    hybridTable.init();
    return hybridTable;
};
