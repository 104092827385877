var Handlebars = require("../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <div class=\"ws10-promotional-card__flag-badge\">\n"
    + ((stack1 = container.invokePartial(require("../flag-badge/_flag-badge.hbs"),(depth0 != null ? lookupProperty(depth0,"containerFlagBadge") : depth0),{"name":"_flag-badge","data":data,"indent":"            ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "        </div>\n";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1, helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <div class=\"ws10-promotional-card__form-selection-control\">\n"
    + ((stack1 = container.invokePartial(require("../form-selection-control/_form-selection-control.hbs"),(depth0 != null ? lookupProperty(depth0,"containerFormSelectionControl") : depth0),{"name":"_form-selection-control","data":data,"indent":"                    ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "                </div>\n                <span class=\"ws10-promotional-card__confirmation-text\">"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"stdConfirmationText") || (depth0 != null ? lookupProperty(depth0,"stdConfirmationText") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"stdConfirmationText","hash":{},"data":data,"loc":{"start":{"line":16,"column":71},"end":{"line":16,"column":94}}}) : helper)))
    + "</span>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"ws10-promotional-card\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"containerFlagBadge") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":2,"column":4},"end":{"line":6,"column":11}}})) != null ? stack1 : "")
    + "    <div class=\"ws10-promotional-card__card\">\n"
    + ((stack1 = container.invokePartial(require("../system-icon/_system-icon.hbs"),depth0,{"name":"_system-icon","hash":{"optColor":"color-inherit","optSize":"size-200","stdClassName":"ws10-promotional-card__icon","stdName":(depth0 != null ? lookupProperty(depth0,"stdIcon") : depth0)},"data":data,"indent":"        ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "        <div class=\"ws10-promotional-card__content\">\n            <h5 class=\"ws10-promotional-card__label\">"
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"stdLabel") || (depth0 != null ? lookupProperty(depth0,"stdLabel") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"stdLabel","hash":{},"data":data,"loc":{"start":{"line":10,"column":53},"end":{"line":10,"column":67}}}) : helper))) != null ? stack1 : "")
    + "</h5>\n            <div class=\"ws10-promotional-card__sublabel ws10-text ws10-spacing-0\">"
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"txtSublabel") || (depth0 != null ? lookupProperty(depth0,"txtSublabel") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"txtSublabel","hash":{},"data":data,"loc":{"start":{"line":11,"column":82},"end":{"line":11,"column":99}}}) : helper))) != null ? stack1 : "")
    + "</div>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"containerFormSelectionControl") : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":12,"column":12},"end":{"line":17,"column":19}}})) != null ? stack1 : "")
    + "        </div>\n    </div>\n</div>\n";
},"usePartial":true,"useData":true});