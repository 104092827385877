/**
 * Tab param nem for deeplinks
 */
export var TABS_URL_PARAM_NAME = 'tab';
/**
 * Tab color types
 */
export var TABS_COLOR_TYPES = ['grey', 'inverse'];
/**
 * Attribute name for aria selection
 */
export var TABS_ARIA_SELECTED = 'aria-selected';
/**
 * Prefix for content ids
 */
export var TABS_CONTENT_ID_PREFIX = 'content-';
/**
 * Base class name
 */
export var TABS_BASE_CLASSNAME = 'ws10-tabs';
/**
 * Tab element class name
 */
export var TABS_TAB_CLASSNAME = "".concat(TABS_BASE_CLASSNAME, "__tab");
/**
 * Track element class name
 */
export var TABS_TRACK_CLASSNAME = "".concat(TABS_BASE_CLASSNAME, "__track");
/**
 * Container class name for tab
 */
export var TABS_CONTAINER_CLASSNAME = "".concat(TABS_BASE_CLASSNAME, "__container");
/**
 * Track auto with version class name
 */
export var TABS_TRACK_AUTO_WIDTH_CLASSNAME = "".concat(TABS_TRACK_CLASSNAME, "--auto-width");
/**
 * Active tab element class name
 */
export var TABS_TAB_ACTIVE_CLASSNAME = "".concat(TABS_TAB_CLASSNAME, "--active");
/**
 * Control container element class name
 */
export var TABS_CONTROL_CLASSNAME = "".concat(TABS_BASE_CLASSNAME, "__control");
/**
 * Control button element class name
 */
export var TABS_BUTTON_CLASSNAME = "".concat(TABS_BASE_CLASSNAME, "__button");
/**
 * Control button element class name
 */
export var TABS_BUTTON_ICON_CLASSNAME = "".concat(TABS_BUTTON_CLASSNAME, "--icon");
/**
 * Content class name
 */
export var TABS_CONTENTS_CLASSNAME = "".concat(TABS_BASE_CLASSNAME, "__contents");
/**
 * Content class name
 */
export var TABS_CONTENT_CLASSNAME = "".concat(TABS_BASE_CLASSNAME, "__content");
/**
 * Active Content class name
 */
export var TABS_CONTENT_ACTIVE_CLASSNAME = "".concat(TABS_CONTENT_CLASSNAME, "--active");
/**
 * Label element class name
 */
export var TABS_LABEL_CLASSNAME = "".concat(TABS_BASE_CLASSNAME, "__label");
