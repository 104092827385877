var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
var __values = (this && this.__values) || function(o) {
    var s = typeof Symbol === "function" && Symbol.iterator, m = s && o[s], i = 0;
    if (m) return m.call(o);
    if (o && typeof o.length === "number") return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
    throw new TypeError(s ? "Object is not iterable." : "Symbol.iterator is not defined.");
};
import './sticky-price-bar.scss';
import renderTemplate from './_sticky-price-bar.hbs';
import { Pattern, removeUndefinedFieldsFromObject, Price, } from '@vfde-brix/ws10/core';
import { STICKY_PRICE_BAR_PRICE_CLASSNAME, STICKY_PRICE_BAR_ITEM_TEXT_CLASSNAME, STICKY_PRICE_BAR_BASE_CLASSNAME, STICKY_PRICE_BAR_ITEM_CLASSNAME, STICKY_PRICE_BAR_UPDATED_CLASSNAME, STICKY_PRICE_BAR_CONDITION_BEFORE_CLASSNAME, STICKY_PRICE_BAR_INTERSECTING_CLASSNAME, } from './Constants';
import { isEqual } from 'lodash';
/**
 * WS10 StickyPriceBar
 */
var StickyPriceBar = /** @class */ (function (_super) {
    __extends(StickyPriceBar, _super);
    function StickyPriceBar() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     * All rendering gets done with this function. If the component contains another
     * component, you would then render the child component in this function.
     */
    StickyPriceBar.prototype.writeDom = function () {
        this.containerElement.innerHTML = renderTemplate(__assign(__assign({}, this.properties), { isIntersecting: this.isIntersecting }));
        this.stickyPriceBarElement = this.containerElement.getElementsByClassName(STICKY_PRICE_BAR_BASE_CLASSNAME)[0];
    };
    /**
     * Intentionally return same props because no props to get defaults
     */
    StickyPriceBar.prototype.getDefaultProperties = function (newProperties) {
        return newProperties;
    };
    /**
     * Toggle the Sticky Price Bar component intersection modifier
     * @param isIntersecting a parameter for adding an intersection class
     * when the component is intersecting with another specific component (For example OfferPrice)
     * The StickyPriceBar should be hidden when the OfferPrice is visible in the viewport
     */
    StickyPriceBar.prototype.toggleIntersection = function (isIntersecting) {
        this.stickyPriceBarElement.classList.toggle(STICKY_PRICE_BAR_INTERSECTING_CLASSNAME, isIntersecting);
    };
    /**
     * Get properties from DOM
     */
    StickyPriceBar.prototype.readDom = function (business) {
        var e_1, _a;
        var _b;
        this.stickyPriceBarElement = this.containerElement.getElementsByClassName(STICKY_PRICE_BAR_BASE_CLASSNAME)[0];
        var itemElements = __spreadArray([], __read(this.stickyPriceBarElement.getElementsByClassName(STICKY_PRICE_BAR_ITEM_CLASSNAME)), false);
        var stickyPriceBarItems = [];
        try {
            for (var itemElements_1 = __values(itemElements), itemElements_1_1 = itemElements_1.next(); !itemElements_1_1.done; itemElements_1_1 = itemElements_1.next()) {
                var itemElement = itemElements_1_1.value;
                var stdText = itemElement.getElementsByClassName(STICKY_PRICE_BAR_ITEM_TEXT_CLASSNAME)[0].textContent.trim();
                var optPriceConditionBefore = (_b = itemElement.getElementsByClassName(STICKY_PRICE_BAR_CONDITION_BEFORE_CLASSNAME)[0]) === null || _b === void 0 ? void 0 : _b.textContent.trim();
                var priceContent = itemElement.getElementsByClassName(STICKY_PRICE_BAR_PRICE_CLASSNAME)[0].textContent.trim();
                var price = Price.fromString(priceContent);
                var itemProperties = {
                    stdText: stdText,
                    optPriceConditionBefore: optPriceConditionBefore,
                    price: price,
                    business: {},
                };
                stickyPriceBarItems.push(removeUndefinedFieldsFromObject(itemProperties));
            }
        }
        catch (e_1_1) { e_1 = { error: e_1_1 }; }
        finally {
            try {
                if (itemElements_1_1 && !itemElements_1_1.done && (_a = itemElements_1.return)) _a.call(itemElements_1);
            }
            finally { if (e_1) throw e_1.error; }
        }
        var properties = removeUndefinedFieldsFromObject({
            stickyPriceBarItems: stickyPriceBarItems,
            business: business,
        });
        return properties;
    };
    /**
     * Trigger an animation when prices are updated
     */
    StickyPriceBar.prototype.animateOnPriceUpdate = function () {
        var _this = this;
        this.stickyPriceBarElement.classList.add(STICKY_PRICE_BAR_UPDATED_CLASSNAME);
        var animationEndHandler = function () {
            _this.stickyPriceBarElement.classList.remove(STICKY_PRICE_BAR_UPDATED_CLASSNAME);
            _this.stickyPriceBarElement.removeEventListener('animationend', animationEndHandler);
        };
        this.stickyPriceBarElement.addEventListener('animationend', animationEndHandler);
    };
    /**
     * This is an override for the update funtion
     * @param newProperties component new propreties
     * @param preventRerender a falg to prevent component from rendering
     * This function compares old and new properties and if they differ it sets the properties and renders
     */
    StickyPriceBar.prototype.update = function (newProperties, preventRerender) {
        if (preventRerender === void 0) { preventRerender = false; }
        var triggerUpdateAnimation;
        this.isIntersecting = this.stickyPriceBarElement.classList.contains(STICKY_PRICE_BAR_INTERSECTING_CLASSNAME);
        var mergedProperties = __assign(__assign({}, this.properties), newProperties);
        // If properties changed, trigger the animation
        if (!isEqual(this.properties, mergedProperties)) {
            triggerUpdateAnimation = true;
        }
        _super.prototype.update.call(this, newProperties, preventRerender);
        if (triggerUpdateAnimation) {
            // trigger animation on update
            this.animateOnPriceUpdate();
        }
    };
    return StickyPriceBar;
}(Pattern));
export { StickyPriceBar };
/**
 * This function returns an instance of StickyPriceBar
 */
export var createStickyPriceBar = function (containerElement, properties) {
    var stickyPriceBar = new StickyPriceBar(containerElement, properties);
    stickyPriceBar.init();
    return stickyPriceBar;
};
