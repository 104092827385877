var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __values = (this && this.__values) || function(o) {
    var s = typeof Symbol === "function" && Symbol.iterator, m = s && o[s], i = 0;
    if (m) return m.call(o);
    if (o && typeof o.length === "number") return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
    throw new TypeError(s ? "Object is not iterable." : "Symbol.iterator is not defined.");
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { HandlebarRenderer, NO_PATTERN_BUSINESS_LOGIC, Pattern, getPropertiesFromClassName, removeUndefinedFieldsFromObject, attempt, } from '@vfde-brix/ws10/core';
import { BUTTON_ICON_ONLY_SHAPE_TRANSPARENT, BUTTON_ICON_ONLY_SIZE_STANDARD, createButtonIconOnly, } from '../button-icon-only';
import { createButtonLink, } from '../button-link';
import { Tooltip } from '../tooltip';
import { createTooltip } from '@vfde-brix/ws10/tooltip';
import { ITEM_SUMMARY_CARD_BASE_CLASSNAME, ITEM_SUMMARY_CARD_BUTTONLINK_CLASSNAME, ITEM_SUMMARY_CARD_COPYTEXT_CLASSNAME, ITEM_SUMMARY_CARD_HEADLINE_CLASSNAME, ITEM_SUMMARY_CARD_ICONBUTTON_CLASSNAME, ITEM_SUMMARY_CARD_ITEM_CLASSNAME, ITEM_SUMMARY_CARD_SUBLINE_CLASSNAME, ITEM_SUMMARY_CARD_SYSTEM_ICON_CLASSNAME, ITEM_SUMMARY_CARD_TEXTLINK_CLASSNAME, ITEM_SUMMARY_CARD_TOOLTIP, OptVariant, } from './Constants';
import renderTemplate from './_item-summary-card.hbs';
import './item-summary-card.scss';
import { getSystemIconName } from '../system-icon';
/**
 * COMMENT FROM SCAFFOLD: Class name should always match the file name.
 * COMMENT FROM SCAFFOLD: All components should inherit Component
 */
var ItemSummaryCard = /** @class */ (function (_super) {
    __extends(ItemSummaryCard, _super);
    function ItemSummaryCard() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        /**
         * Handle click via event delegation
         */
        _this.handleOnClick = function (event) {
            var target = event.target;
            _this.handleDeleteButtonClick(target, event);
            _this.handleButtonLinkClick(target, event);
        };
        return _this;
    }
    ItemSummaryCard.prototype.getDefaultProperties = function (newProperties) {
        return newProperties;
    };
    ItemSummaryCard.prototype.initDomElements = function () {
        var buttonIconOnlyContainer = this.containerElement.getElementsByClassName(ITEM_SUMMARY_CARD_ICONBUTTON_CLASSNAME)[0];
        var buttonIconOnlyProperties = {
            stdIconName: 'delete',
            stdAriaLabel: 'delete',
            business: {},
            optShape: BUTTON_ICON_ONLY_SHAPE_TRANSPARENT,
            optSize: BUTTON_ICON_ONLY_SIZE_STANDARD,
        };
        buttonIconOnlyContainer ? createButtonIconOnly(buttonIconOnlyContainer, buttonIconOnlyProperties) : undefined;
    };
    /**
     * Render this component to the DOM
     */
    ItemSummaryCard.prototype.writeDom = function () {
        var e_1, _a;
        HandlebarRenderer.render(this.containerElement, renderTemplate(this.properties));
        this.initDomElements();
        var tooltipElements = this.containerElement.getElementsByClassName(ITEM_SUMMARY_CARD_TOOLTIP);
        try {
            for (var tooltipElements_1 = __values(tooltipElements), tooltipElements_1_1 = tooltipElements_1.next(); !tooltipElements_1_1.done; tooltipElements_1_1 = tooltipElements_1.next()) {
                var tooltipElement = tooltipElements_1_1.value;
                createTooltip(tooltipElement, NO_PATTERN_BUSINESS_LOGIC);
            }
        }
        catch (e_1_1) { e_1 = { error: e_1_1 }; }
        finally {
            try {
                if (tooltipElements_1_1 && !tooltipElements_1_1.done && (_a = tooltipElements_1.return)) _a.call(tooltipElements_1);
            }
            finally { if (e_1) throw e_1.error; }
        }
    };
    /**
     *
     */
    ItemSummaryCard.prototype.readDom = function (businessLogic) {
        var e_2, _a;
        var _b, _c, _d;
        var itemSummaryCardWrapperElement = this.containerElement.getElementsByClassName(ITEM_SUMMARY_CARD_BASE_CLASSNAME)[0];
        var itemSummaryCardItemElements = __spreadArray([], __read(this.containerElement.getElementsByClassName(ITEM_SUMMARY_CARD_ITEM_CLASSNAME)), false);
        var properties = {
            business: businessLogic,
            items: [],
            optVariant: this.getOptVariant(itemSummaryCardWrapperElement),
        };
        try {
            for (var itemSummaryCardItemElements_1 = __values(itemSummaryCardItemElements), itemSummaryCardItemElements_1_1 = itemSummaryCardItemElements_1.next(); !itemSummaryCardItemElements_1_1.done; itemSummaryCardItemElements_1_1 = itemSummaryCardItemElements_1.next()) {
                var itemElement = itemSummaryCardItemElements_1_1.value;
                var business = {};
                var iconElement = itemElement.getElementsByClassName(ITEM_SUMMARY_CARD_SYSTEM_ICON_CLASSNAME)[0];
                var stdIcon = (iconElement && this.getSystemIconName(itemElement)) || undefined;
                var image = this.getImagePropertiesFromDOM(itemElement);
                var stdHeadline = (_b = itemElement.getElementsByClassName(ITEM_SUMMARY_CARD_HEADLINE_CLASSNAME)[0]) === null || _b === void 0 ? void 0 : _b.innerHTML.trim();
                var containerTooltip = Tooltip.getPropertiesFromDom(this.containerElement);
                var stdSubline = (_c = itemElement.getElementsByClassName(ITEM_SUMMARY_CARD_SUBLINE_CLASSNAME)[0]) === null || _c === void 0 ? void 0 : _c.innerHTML.trim();
                var containerCopytext = (_d = itemElement.getElementsByClassName(ITEM_SUMMARY_CARD_COPYTEXT_CLASSNAME)[0]) === null || _d === void 0 ? void 0 : _d.innerHTML.trim();
                var textLinkItems = this.getTextLinkPropertiesFromDOM(itemElement);
                var buttonLinkItems = this.getButtonLinkPropertiesFromDOM(itemElement);
                var optIconButton = !!itemElement.getElementsByClassName(ITEM_SUMMARY_CARD_ICONBUTTON_CLASSNAME).length || undefined;
                properties.items.push(removeUndefinedFieldsFromObject({
                    business: business,
                    stdIcon: stdIcon,
                    stdHeadline: stdHeadline,
                    image: image,
                    containerTooltip: containerTooltip,
                    stdSubline: stdSubline,
                    containerCopytext: containerCopytext,
                    textLinkItems: textLinkItems,
                    buttonLinkItems: buttonLinkItems,
                    optIconButton: optIconButton,
                }));
            }
        }
        catch (e_2_1) { e_2 = { error: e_2_1 }; }
        finally {
            try {
                if (itemSummaryCardItemElements_1_1 && !itemSummaryCardItemElements_1_1.done && (_a = itemSummaryCardItemElements_1.return)) _a.call(itemSummaryCardItemElements_1);
            }
            finally { if (e_2) throw e_2.error; }
        }
        return properties;
    };
    /**
     *  Extracts the image properties (desktop source, mobile source, and alt text) from the provided item element.
     *  @returns {object | undefined} An object containing imgSrcDesktop, imgSrcMobile, and stdImgAlt properties or undefined if no image is found.
     */
    ItemSummaryCard.prototype.getImagePropertiesFromDOM = function (itemElement) {
        var imgElement = itemElement.getElementsByTagName('img')[0];
        if (!imgElement) {
            return undefined;
        }
        var sourceElement = itemElement.getElementsByTagName('source')[0];
        var imgSrcDesktop = imgElement.getAttribute('src');
        var imgSrcMobile = sourceElement.getAttribute('srcset');
        var stdImgAlt = imgElement.getAttribute('alt');
        return removeUndefinedFieldsFromObject({
            imgSrcDesktop: imgSrcDesktop,
            imgSrcMobile: imgSrcMobile,
            stdImgAlt: stdImgAlt,
        });
    };
    /**
     * Extracts the text link properties from a given Element.
     * @returns {Array} An array of objects representing the properties of each text link.
     */
    ItemSummaryCard.prototype.getTextLinkPropertiesFromDOM = function (itemElement) {
        var textLinks = itemElement.getElementsByClassName(ITEM_SUMMARY_CARD_TEXTLINK_CLASSNAME);
        if (textLinks && textLinks.length > 0) {
            return Array.from(textLinks).map(function (div) {
                var anchor = div.querySelector('a');
                return {
                    stdLinkText: anchor.text.trim(),
                    linkHref: anchor.getAttribute('href'),
                };
            });
        }
        return undefined;
    };
    ItemSummaryCard.prototype.getButtonLinkPropertiesFromDOM = function (itemSummaryCard) {
        var items = [];
        return attempt(function () {
            var buttonLinkItems = itemSummaryCard.getElementsByClassName(ITEM_SUMMARY_CARD_BUTTONLINK_CLASSNAME);
            var buttonLinkArray = Array.from(buttonLinkItems);
            buttonLinkArray.forEach(function (buttonLinkAnchor) {
                var buttonLink = createButtonLink(buttonLinkAnchor, NO_PATTERN_BUSINESS_LOGIC);
                items.push(buttonLink.getProperties());
            });
            return items.length ? items : undefined;
        });
    };
    /**
     * get system icon name from DOM with a check of it's existence
     */
    ItemSummaryCard.prototype.getSystemIconName = function (itemSummaryCarditem) {
        var systemIconElement = itemSummaryCarditem.getElementsByClassName(ITEM_SUMMARY_CARD_SYSTEM_ICON_CLASSNAME)[0];
        var iconHref = getSystemIconName(systemIconElement);
        return iconHref
            ? iconHref.split('/').pop().split('.')[0]
            : undefined;
    };
    /**
     * Searches the _item-summary-card.hbs for classnames that are used to indicate the wrapper type.
     * @param wrapper | Anchor Element of an individual Item Summary Card
     * @returns Returns the corresponding enum that represents the alignment
     */
    ItemSummaryCard.prototype.getOptVariant = function (wrapper) {
        var propertiesFromClassname = getPropertiesFromClassName(wrapper.className, {
            optVariant: [OptVariant.Standalone, OptVariant.Nested],
        });
        return propertiesFromClassname.optVariant;
    };
    ItemSummaryCard.prototype.initEvents = function () {
        var _a = __read(this.containerElement.getElementsByClassName(ITEM_SUMMARY_CARD_BASE_CLASSNAME), 1), itemSummaryCardList = _a[0];
        itemSummaryCardList.addEventListener('click', this.handleOnClick);
    };
    ItemSummaryCard.prototype.handleButtonLinkClick = function (target, event) {
        var buttonLinkContainer = target.closest(".".concat(ITEM_SUMMARY_CARD_BUTTONLINK_CLASSNAME));
        if (!buttonLinkContainer) {
            return;
        }
        var onButtonLinkClick = this.properties.business.onButtonLinkClick;
        var itemSummaryCardItem = buttonLinkContainer.closest(".".concat(ITEM_SUMMARY_CARD_ITEM_CLASSNAME));
        var itemSummaryCard = this.getContainerElement();
        var allItems = Array.prototype.slice.call(itemSummaryCard.getElementsByClassName(ITEM_SUMMARY_CARD_ITEM_CLASSNAME));
        var itemIndex = allItems.indexOf(itemSummaryCardItem);
        var allButtonLinks = Array.prototype.slice.call(itemSummaryCard.getElementsByClassName(ITEM_SUMMARY_CARD_BUTTONLINK_CLASSNAME));
        var linkIndex = allButtonLinks.indexOf(buttonLinkContainer);
        var buttonLink = buttonLinkContainer.firstElementChild;
        onButtonLinkClick && onButtonLinkClick(event, linkIndex, itemIndex, buttonLink.getAttribute('href'), buttonLink.dataset.trackingName);
    };
    ItemSummaryCard.prototype.handleDeleteButtonClick = function (target, event) {
        var buttonContainer = target.closest(".".concat(ITEM_SUMMARY_CARD_ICONBUTTON_CLASSNAME));
        if (!buttonContainer) {
            return;
        }
        var onDeleteButtonClick = this.properties.business.onDeleteButtonClick;
        var itemSummaryCardItem = buttonContainer.closest(".".concat(ITEM_SUMMARY_CARD_ITEM_CLASSNAME));
        var itemSummaryCard = this.getContainerElement();
        var allItems = Array.prototype.slice.call(itemSummaryCard.getElementsByClassName(ITEM_SUMMARY_CARD_ITEM_CLASSNAME));
        var index = allItems.indexOf(itemSummaryCardItem);
        onDeleteButtonClick && onDeleteButtonClick(event, index);
    };
    return ItemSummaryCard;
}(Pattern));
export { ItemSummaryCard };
/**
 * This function returns an instance of ItemSummaryCard
 */
export var createItemSummaryCard = function (containerElement, businessLogicOrProperties) {
    var itemSummaryCard = new ItemSummaryCard(containerElement, businessLogicOrProperties);
    itemSummaryCard.init();
    return itemSummaryCard;
};
