var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __values = (this && this.__values) || function(o) {
    var s = typeof Symbol === "function" && Symbol.iterator, m = s && o[s], i = 0;
    if (m) return m.call(o);
    if (o && typeof o.length === "number") return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
    throw new TypeError(s ? "Object is not iterable." : "Symbol.iterator is not defined.");
};
import './image-gallery.scss';
import renderTemplate from './_image-gallery.hbs';
import { loadImage, Pattern, removeUndefinedFieldsFromObject, toggleElementVisibility, } from '@vfde-brix/ws10/core';
import { getSystemIconName } from '@vfde-brix/ws10/system-icon';
import { IMAGE_GALLERY_SLIDER, IMAGE_GALLERY_IMAGE_ITEM, IMAGE_GALLERY_IMAGES_IMAGE, IMAGE_GALLERY_SLIDER_IMAGE, IMAGE_GALLERY_ICON, IMAGE_GALLERY_MIN_ITEM_COUNT, IMAGE_GALLERY_MAX_ITEM_COUNT, } from './Constants';
import Splide from '@splidejs/splide';
/**
 * Image Gallery class
 */
var ImageGallery = /** @class */ (function (_super) {
    __extends(ImageGallery, _super);
    function ImageGallery() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     * Component init method
     */
    ImageGallery.prototype.afterInit = function () {
        if (this.properties.items.length < IMAGE_GALLERY_MIN_ITEM_COUNT
            || this.properties.items.length > IMAGE_GALLERY_MAX_ITEM_COUNT) {
            throw new RangeError("Number of items must be between ".concat(IMAGE_GALLERY_MIN_ITEM_COUNT, " and ").concat(IMAGE_GALLERY_MAX_ITEM_COUNT));
        }
    };
    /**
     * Get config for the main slider
     */
    ImageGallery.getSliderConfig = function () {
        return {
            pagination: true,
            arrows: false,
            classes: {
                root: IMAGE_GALLERY_SLIDER,
                track: "".concat(IMAGE_GALLERY_SLIDER, "__track"),
                list: "".concat(IMAGE_GALLERY_SLIDER, "__list"),
                slide: "".concat(IMAGE_GALLERY_SLIDER, "__slide"),
                pagination: "".concat(IMAGE_GALLERY_SLIDER, "__pagination"),
                page: "".concat(IMAGE_GALLERY_SLIDER, "__pagination__page"),
            },
        };
    };
    /**
     * Render component
     */
    ImageGallery.prototype.writeDom = function () {
        this.containerElement.innerHTML = renderTemplate(this.properties);
    };
    ImageGallery.prototype.initDom = function () {
        this.galleryImages = this.getGalleryImages();
        this.iconContainer = this.getIconContainer();
    };
    /**
     * Read component properties from DOM
     */
    ImageGallery.prototype.readDom = function (imageGalleryBusinessLogic) {
        var e_1, _a;
        var items = this.containerElement.getElementsByClassName(IMAGE_GALLERY_IMAGE_ITEM);
        var iconElement = this.containerElement.getElementsByTagName('svg')[0];
        var stdIconName;
        if (iconElement) {
            stdIconName = getSystemIconName(iconElement);
        }
        var properties = {
            items: [],
            stdIconName: stdIconName,
            business: imageGalleryBusinessLogic,
        };
        try {
            for (var items_1 = __values(items), items_1_1 = items_1.next(); !items_1_1.done; items_1_1 = items_1.next()) {
                var item = items_1_1.value;
                var itemProps = removeUndefinedFieldsFromObject({
                    imgSrc: item.getAttribute('src'),
                    stdImgAlt: item.getAttribute('alt'),
                });
                properties.items.push(itemProps);
            }
        }
        catch (e_1_1) { e_1 = { error: e_1_1 }; }
        finally {
            try {
                if (items_1_1 && !items_1_1.done && (_a = items_1.return)) _a.call(items_1);
            }
            finally { if (e_1) throw e_1.error; }
        }
        return removeUndefinedFieldsFromObject(properties);
    };
    /**
     * Get default properties
     */
    ImageGallery.prototype.getDefaultProperties = function (newProperties) {
        return newProperties;
    };
    /**
     * This is an abstract function that every component needs to add event listeners to DOM elements
     */
    ImageGallery.prototype.initEvents = function () {
        this.initSlider();
        this.sliderImages = this.getSliderImages();
    };
    /**
     * Updates the items
     * @param items The new items
     * @param async A boolean indicating if the update should be done asynchronously (default = true).
     * If the update is async, all the images will be loaded first, and then updated in the DOM at once.
     * If the update is sync, the images will be updated in the DOM immediately, which could cause the old
     * images to disappear immediately and the new images to appear with a delay.
     * @param shouldRefreshSlider If true, the slider will re-initialize itself (default=false).
     * @param swipeSliderTo A splide control (number or string) to navigate the slider to.
     */
    ImageGallery.prototype.updateItems = function (items, async, shouldRefreshSlider, swipeSliderTo) {
        if (async === void 0) { async = true; }
        if (shouldRefreshSlider === void 0) { shouldRefreshSlider = false; }
        return __awaiter(this, void 0, Promise, function () {
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (items.length > IMAGE_GALLERY_MAX_ITEM_COUNT) {
                            throw new RangeError("Number of items exceeds the maximum allowed number (".concat(IMAGE_GALLERY_MAX_ITEM_COUNT, ")"));
                        }
                        if (!async) return [3 /*break*/, 2];
                        return [4 /*yield*/, Promise.all(items.map(function (item) { return loadImage(item.imgSrc); }))];
                    case 1:
                        _a.sent();
                        _a.label = 2;
                    case 2:
                        this.properties.items = items;
                        this.properties.items.forEach(function (item, index) {
                            var galleryImageElement = _this.galleryImages[index];
                            var sliderImageElement = _this.sliderImages[index];
                            // set img sources
                            galleryImageElement.src = item.imgSrc;
                            sliderImageElement.src = item.imgSrc;
                            // set alt attributes
                            galleryImageElement.alt = item.stdImgAlt;
                            sliderImageElement.alt = item.stdImgAlt;
                        });
                        if (shouldRefreshSlider) {
                            this.slider.refresh();
                        }
                        if (swipeSliderTo !== undefined) {
                            this.slider.go(swipeSliderTo);
                        }
                        return [2 /*return*/];
                }
            });
        });
    };
    /**
     * Updates the system icon separately
     * @param stdIconName The system icon name. Pass `null` to hide the icon.
     */
    ImageGallery.prototype.updateIcon = function (stdIconName) {
        if (this.properties.stdIconName === stdIconName) {
            return;
        }
        this.properties.stdIconName = stdIconName;
        this.iconContainer.innerHTML = this.renderPartially(renderTemplate, this.properties, ".".concat(IMAGE_GALLERY_ICON))[0];
        toggleElementVisibility(this.iconContainer, !!this.properties.stdIconName);
    };
    /**
     * Get the Slider Element from the DOM
     */
    ImageGallery.prototype.getSliderElement = function () {
        return this.containerElement.getElementsByClassName(IMAGE_GALLERY_SLIDER)[0];
    };
    ImageGallery.prototype.initSlider = function () {
        this.slider = new Splide(this.getSliderElement(), ImageGallery.getSliderConfig());
        this.slider.mount();
    };
    ImageGallery.prototype.getGalleryImages = function () {
        return Array.from(this.containerElement.getElementsByClassName(IMAGE_GALLERY_IMAGES_IMAGE));
    };
    ImageGallery.prototype.getSliderImages = function () {
        return Array.from(this.containerElement.getElementsByClassName(IMAGE_GALLERY_SLIDER_IMAGE));
    };
    ImageGallery.prototype.getIconContainer = function () {
        return this.containerElement.getElementsByClassName(IMAGE_GALLERY_ICON)[0];
    };
    return ImageGallery;
}(Pattern));
export { ImageGallery };
/**
 * This function returns an instance of ImageGallery
 */
export var createImageGallery = function (containerElement, businessLogicOrProperties) {
    var imageGallery = new ImageGallery(containerElement, businessLogicOrProperties);
    imageGallery.init();
    return imageGallery;
};
