var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
// import styles
import './notification.scss';
import renderTemplate from './_notification.hbs';
import { Pattern, getPropertiesFromClassName, NO_PATTERN_BUSINESS_LOGIC, removeUndefinedFieldsFromObject, HandlebarRenderer, } from '@vfde-brix/ws10/core';
import { NOTIFICATION_ARROW_VERTICAL_POSITIONS, NOTIFICATION_COLOR_TRANSPARENT, NOTIFICATION_COLOR_WHITE, NOTIFICATION_SIZE_SMALL, NOTIFICATION_TEXT_CLASSNAME, NOTIFICATION_HEADLINE_CLASSNAME, NOTIFICATION_BUTTONS_CLASSNAME, NOTIFICATION_BASE_CLASSNAME, NOTIFICATION_ARROW_CLASSNAME, } from './Constants';
import { NOTIFICATION_ICON_STATES, } from '@vfde-brix/ws10/notification-icon';
import { BUTTON_BASE_CLASSNAME, createButton, } from '@vfde-brix/ws10/button';
import { createButtonLink, WS10_BUTTON_LINK_BASE_CLASSNAME, } from '@vfde-brix/ws10/button-link';
/**
 * Notification component
 */
var Notification = /** @class */ (function (_super) {
    __extends(Notification, _super);
    function Notification() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        /**
         * Handle click via event delegation
         */
        _this.handleOnClick = function (event) {
            var target = event.target;
            _this.handleButtonLinkClick(target, event);
        };
        return _this;
    }
    /**
     * All rendering gets done with this function. If the component contains another
     * component, you would then render the child component in this function.
     */
    Notification.prototype.writeDom = function () {
        HandlebarRenderer.render(this.containerElement, renderTemplate(this.properties));
    };
    /**
     * Intentionally return same props. Because no default props to set, but abstract needs implementation
     */
    Notification.prototype.getDefaultProperties = function (newProperties) {
        return newProperties;
    };
    Notification.prototype.initEvents = function () {
        var _a = __read(this.containerElement.getElementsByClassName(NOTIFICATION_BASE_CLASSNAME), 1), notification = _a[0];
        notification.addEventListener('click', this.handleOnClick);
    };
    Notification.prototype.handleButtonLinkClick = function (target, event) {
        var buttonLink = target.closest(".".concat(WS10_BUTTON_LINK_BASE_CLASSNAME));
        if (!buttonLink) {
            return;
        }
        var onClick = this.properties.business.onClick;
        var notification = this.getContainerElement();
        var allButtonLinks = Array.prototype.slice.call(notification.getElementsByClassName(WS10_BUTTON_LINK_BASE_CLASSNAME));
        var linkIndex = allButtonLinks.indexOf(buttonLink);
        onClick && onClick(event, linkIndex, buttonLink.getAttribute('href'), buttonLink.dataset.trackingName, this);
    };
    /**
     * Get properties from DOM
     */
    Notification.prototype.readDom = function (notificationBusinessLogic) {
        var notificationElement = this.containerElement.getElementsByClassName(NOTIFICATION_BASE_CLASSNAME)[0];
        var propertiesFromClassName = getPropertiesFromClassName(notificationElement.className, {
            optState: NOTIFICATION_ICON_STATES,
            optSize: [NOTIFICATION_SIZE_SMALL],
            optBackground: [NOTIFICATION_COLOR_TRANSPARENT, NOTIFICATION_COLOR_WHITE],
        });
        var notificationHeadlineElement = notificationElement.getElementsByClassName(NOTIFICATION_HEADLINE_CLASSNAME)[0];
        var stdHeadline;
        if (notificationHeadlineElement) {
            stdHeadline = notificationHeadlineElement.innerHTML.trim();
        }
        var notificationTextElement = notificationElement.getElementsByClassName(NOTIFICATION_TEXT_CLASSNAME)[0];
        var txtContent;
        if (notificationTextElement) {
            txtContent = notificationTextElement.innerHTML.trim();
        }
        var optArrowVerticalPosition;
        var arrowElement = notificationElement.getElementsByClassName(NOTIFICATION_ARROW_CLASSNAME);
        if (arrowElement.length === 1) {
            var arrowPropertiesFromClassName = getPropertiesFromClassName(arrowElement[0].className, {
                optArrowVerticalPosition: NOTIFICATION_ARROW_VERTICAL_POSITIONS,
            });
            optArrowVerticalPosition = arrowPropertiesFromClassName.optArrowVerticalPosition;
        }
        var properties = {
            optState: propertiesFromClassName.optState,
            optSize: propertiesFromClassName.optSize,
            optBackground: propertiesFromClassName.optBackground,
            optArrowVerticalPosition: optArrowVerticalPosition,
            stdHeadline: stdHeadline,
            txtContent: txtContent,
            business: notificationBusinessLogic,
        };
        var buttonsContainerElement = notificationElement.getElementsByClassName(NOTIFICATION_BUTTONS_CLASSNAME);
        if (buttonsContainerElement.length !== 0) {
            var containerButtons = [];
            var buttonElements = notificationElement.getElementsByClassName(BUTTON_BASE_CLASSNAME);
            for (var i = 0, x = buttonElements.length; i < x; i += 1) {
                var buttonContainer = buttonElements[i].parentNode;
                var button = createButton(buttonContainer, NO_PATTERN_BUSINESS_LOGIC);
                containerButtons.push(button.getProperties());
            }
            var buttonLinkElements = notificationElement.getElementsByClassName(WS10_BUTTON_LINK_BASE_CLASSNAME);
            for (var i = 0, x = buttonLinkElements.length; i < x; i += 1) {
                var buttonLinkContainer = buttonLinkElements[i].parentNode;
                var buttonLink = createButtonLink(buttonLinkContainer, NO_PATTERN_BUSINESS_LOGIC);
                containerButtons.push(buttonLink.getProperties());
            }
            properties.containerButtons = containerButtons;
        }
        return removeUndefinedFieldsFromObject(properties);
    };
    return Notification;
}(Pattern));
export { Notification };
/**
 * All components should have a factory function to create the component
 * This function returns an instance of Notification
 */
export var createNotification = function (containerElement, properties) {
    var notification = new Notification(containerElement, properties);
    notification.init();
    return notification;
};
