var Handlebars = require("../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return " ws10-tabs--"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"optColor") || (depth0 != null ? lookupProperty(depth0,"optColor") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"optColor","hash":{},"data":data,"loc":{"start":{"line":1,"column":49},"end":{"line":1,"column":61}}}) : helper)));
},"3":function(container,depth0,helpers,partials,data) {
    return "        <div class=\"ws10-tabs__container ws10-belt-container\">\n        <div class=\"ws10-belt-container__belt\">\n";
},"5":function(container,depth0,helpers,partials,data) {
    return "        <div class=\"ws10-tabs__container\">\n";
},"7":function(container,depth0,helpers,partials,data) {
    return " ws10-tabs__track--auto-width";
},"9":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    <li id=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"stdId") || (depth0 != null ? lookupProperty(depth0,"stdId") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"stdId","hash":{},"data":data,"loc":{"start":{"line":11,"column":28},"end":{"line":11,"column":37}}}) : helper)))
    + "\" class=\"ws10-tabs__tab"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"optActive") : depth0),{"name":"if","hash":{},"fn":container.program(10, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":11,"column":60},"end":{"line":11,"column":107}}})) != null ? stack1 : "")
    + "\">\n                        <button id=\"control-"
    + alias4(((helper = (helper = lookupProperty(helpers,"stdId") || (depth0 != null ? lookupProperty(depth0,"stdId") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"stdId","hash":{},"data":data,"loc":{"start":{"line":12,"column":44},"end":{"line":12,"column":53}}}) : helper)))
    + "\" class=\"ws10-tabs__button"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"stdIcon") : depth0),{"name":"if","hash":{},"fn":container.program(12, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":12,"column":79},"end":{"line":12,"column":125}}})) != null ? stack1 : "")
    + "\" type=\"button\" "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"optDisabled") : depth0),{"name":"if","hash":{},"fn":container.program(14, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":12,"column":141},"end":{"line":12,"column":175}}})) != null ? stack1 : "")
    + " role=\"tab\" aria-controls=\"content-"
    + alias4(((helper = (helper = lookupProperty(helpers,"stdId") || (depth0 != null ? lookupProperty(depth0,"stdId") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"stdId","hash":{},"data":data,"loc":{"start":{"line":12,"column":210},"end":{"line":12,"column":219}}}) : helper)))
    + "\" aria-selected=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"optActive") || (depth0 != null ? lookupProperty(depth0,"optActive") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"optActive","hash":{},"data":data,"loc":{"start":{"line":12,"column":236},"end":{"line":12,"column":249}}}) : helper)))
    + "\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"stdIcon") : depth0),{"name":"if","hash":{},"fn":container.program(16, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":13,"column":28},"end":{"line":15,"column":35}}})) != null ? stack1 : "")
    + "                            <span class=\"ws10-tabs__label\" data-text=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"stdLabel") || (depth0 != null ? lookupProperty(depth0,"stdLabel") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"stdLabel","hash":{},"data":data,"loc":{"start":{"line":16,"column":70},"end":{"line":16,"column":82}}}) : helper)))
    + "\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"stdLabel") || (depth0 != null ? lookupProperty(depth0,"stdLabel") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"stdLabel","hash":{},"data":data,"loc":{"start":{"line":16,"column":84},"end":{"line":16,"column":96}}}) : helper)))
    + "</span>\n                        </button>\n                    </li>\n";
},"10":function(container,depth0,helpers,partials,data) {
    return " ws10-tabs__tab--active";
},"12":function(container,depth0,helpers,partials,data) {
    return " ws10-tabs__button--icon";
},"14":function(container,depth0,helpers,partials,data) {
    return "disabled";
},"16":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = container.invokePartial(require("../system-icon/_system-icon.hbs"),depth0,{"name":"_system-icon","hash":{"stdClassName":"ws10-tabs__icon","stdName":(depth0 != null ? lookupProperty(depth0,"stdIcon") : depth0)},"data":data,"indent":"                                ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"18":function(container,depth0,helpers,partials,data) {
    return "        </div>\n";
},"20":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <li id=\"content-"
    + alias4(((helper = (helper = lookupProperty(helpers,"stdId") || (depth0 != null ? lookupProperty(depth0,"stdId") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"stdId","hash":{},"data":data,"loc":{"start":{"line":28,"column":28},"end":{"line":28,"column":37}}}) : helper)))
    + "\" class=\"ws10-tabs__content"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"optActive") : depth0),{"name":"if","hash":{},"fn":container.program(21, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":28,"column":64},"end":{"line":28,"column":115}}})) != null ? stack1 : "")
    + "\" data-for=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"stdId") || (depth0 != null ? lookupProperty(depth0,"stdId") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"stdId","hash":{},"data":data,"loc":{"start":{"line":28,"column":127},"end":{"line":28,"column":136}}}) : helper)))
    + "\" role=\"tabpanel\" aria-labelledby=\"control-"
    + alias4(((helper = (helper = lookupProperty(helpers,"stdId") || (depth0 != null ? lookupProperty(depth0,"stdId") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"stdId","hash":{},"data":data,"loc":{"start":{"line":28,"column":179},"end":{"line":28,"column":188}}}) : helper)))
    + "\">\n                "
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"containerAnyComponent") || (depth0 != null ? lookupProperty(depth0,"containerAnyComponent") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"containerAnyComponent","hash":{},"data":data,"loc":{"start":{"line":29,"column":16},"end":{"line":29,"column":43}}}) : helper))) != null ? stack1 : "")
    + "\n            </li>\n";
},"21":function(container,depth0,helpers,partials,data) {
    return " ws10-tabs__content--active";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"ws10-tabs"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"optColor") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":21},"end":{"line":1,"column":68}}})) != null ? stack1 : "")
    + "\" data-deeplink=\""
    + container.escapeExpression(container.lambda((depth0 != null ? lookupProperty(depth0,"optDeepLink") : depth0), depth0))
    + "\">\n    <div class=\"ws10-tabs__control\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"optBeltContainer") : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.program(5, data, 0),"data":data,"loc":{"start":{"line":3,"column":8},"end":{"line":8,"column":15}}})) != null ? stack1 : "")
    + "            <ul class=\"ws10-tabs__track"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"optAutoWidth") : depth0),{"name":"if","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":9,"column":39},"end":{"line":9,"column":95}}})) != null ? stack1 : "")
    + "\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"listTabs") : depth0),{"name":"each","hash":{},"fn":container.program(9, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":10,"column":16},"end":{"line":19,"column":25}}})) != null ? stack1 : "")
    + "            </ul>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"optBeltContainer") : depth0),{"name":"if","hash":{},"fn":container.program(18, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":21,"column":8},"end":{"line":23,"column":15}}})) != null ? stack1 : "")
    + "        </div>\n    </div>\n    <ul class=\"ws10-tabs__contents\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"listTabs") : depth0),{"name":"each","hash":{},"fn":container.program(20, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":27,"column":8},"end":{"line":31,"column":17}}})) != null ? stack1 : "")
    + "    </ul>\n</div>\n";
},"usePartial":true,"useData":true});