var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
/**
 * This is a basic component which does not have component inside component.
 * To see an example of a component inside component, check OptionTabs.ts. Option Tabs
 * has RibbonBadge used inside of it. Pay careful attention to the render function,
 * the ribbon badge import and the added dependency in the package.json of option tabs.
 */
import './form-suggest-input.scss';
import renderTemplate from './_form-suggest-input.hbs';
import { attempt, getViewportName, Pattern, removeUndefinedFieldsFromObject, fixScrollingAfterIOSKeyboard, isSafariOniOS, } from '@vfde-brix/ws10/core';
import { FORM_SUGGEST_INPUT_BASE_CLASSNAME, FORM_SUGGEST_INPUT_CLASSNAME_ERROR, FORM_SUGGEST_INPUT_CLASSNAME_SUCCESS, FORM_SUGGEST_INPUT_CLASSNAME_WARN, FORM_SUGGEST_INPUT_CLASSNAME_INPUT_CONTAINER, FORM_SUGGEST_INPUT_CLASSNAME_INPUT_DISABLED, FORM_SUGGEST_INPUT_CLASSNAME_INPUT_FOCUSED, FORM_SUGGEST_INPUT_CLASSNAME_RESULT_ITEM, FORM_SUGGEST_INPUT_CLASSNAME_RESULT_ITEM_ICON, FORM_SUGGEST_INPUT_CLASSNAME_RESULT_LIST, FORM_SUGGEST_INPUT_CLASSNAME_RESULT_LIST_ICONIZED, FORM_SUGGEST_INPUT_CLASSNAME_RESULT_LIST_LIMITED, FORM_SUGGEST_INPUT_CLASSNAME_RESULT_WRAPPER, FORM_SUGGEST_INPUT_CLASSNAME_RESULT_ITEM_ACTIVE, FORM_SUGGEST_INPUT_EVENT_KEY_ARROW_DOWN, FORM_SUGGEST_INPUT_EVENT_KEY_ARROW_UP, FORM_SUGGEST_INPUT_EVENT_KEY_ESCAPE, FORM_SUGGEST_INPUT_EVENT_KEY_TAB, FORM_SUGGEST_INPUT_EVENT_KEY_ENTER, FORM_SUGGEST_INPUT_MAX_ITEMS_BEFORE_SCROLLBAR, FORM_SUGGEST_INPUT_SYSTEM_ICON_WHEN_CLOSED, FORM_SUGGEST_INPUT_SYSTEM_ICON_WHEN_OPENED, FORM_SUGGEST_INPUT_CLASSNAME_HAS_RESULTS, FORM_SUGGEST_INPUT_RESULT_LIST_ID_PREFIX, } from './Constants';
import { FORM_TEXT_INPUT_INPUT_CLASSNAME as FORM_TEXT_INPUT_INPUT_CLASSNAME, } from '@vfde-brix/ws10/form-text-input';
import { CLASSNAME_HIDDEN, CLASSNAME_NO_SCROLL, MOBILE_ONLY, } from '@vfde-brix/ws10/styles';
import { createFormElement, FORM_ELEMENT_BLOCK_ERROR_MESSAGE_CLASSNAME, FORM_ELEMENT_BLOCK_HELPER_TEXT_CLASSNAME, } from '@vfde-brix/ws10/form-element';
/**
 * Class name should always match the file name.
 * All components should inherit Component
 */
var FormSuggestInput = /** @class */ (function (_super) {
    __extends(FormSuggestInput, _super);
    function FormSuggestInput() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        /**
         * Renders the FormSuggestInput with the given properties
         * @param properties The properties
         * @returns The compiled template as HTML string
         */
        _this.render = function (properties) {
            var optRole = 'combobox';
            var stdAriaControls = "".concat(FORM_SUGGEST_INPUT_RESULT_LIST_ID_PREFIX).concat(properties.stdId ? "-".concat(properties.stdId) : '');
            var stdAriaAutocomplete = 'list';
            var renderProperties = __assign(__assign({}, properties), { optRole: optRole, stdAriaControls: stdAriaControls, stdAriaAutocomplete: stdAriaAutocomplete });
            return renderTemplate(renderProperties);
        };
        /**
         * Handle onChange input field (mandatory handler for input field)
         * This is an arrow function because we want to keep our 'this' context
         * when we pass it to 'addEventListener'
         */
        _this.handleChange = function (event, value) {
            var _a, _b;
            /* istanbul ignore else */
            if ((_b = (_a = _this.properties) === null || _a === void 0 ? void 0 : _a.business) === null || _b === void 0 ? void 0 : _b.onChange) {
                _this.properties.business.onChange(event, value);
            }
        };
        /**
         * Handle onBlur (needed for input validation)
         */
        _this.handleBlur = function (event, value) {
            var _a, _b;
            /* istanbul ignore else */
            if ((_b = (_a = _this.properties) === null || _a === void 0 ? void 0 : _a.business) === null || _b === void 0 ? void 0 : _b.onBlur) {
                _this.properties.business.onBlur(event, value);
            }
        };
        /**
         * Handle onFocus input field (mandatory handler for input field)
         * This is an arrow function because we want to keep our 'this' context
         * when we pass it to 'addEventListener'
         */
        _this.handleFocus = function (event, value) {
            var _a, _b;
            _this.showResultList();
            if (isSafariOniOS()) {
                fixScrollingAfterIOSKeyboard();
            }
            /* istanbul ignore else */
            if ((_b = (_a = _this.properties) === null || _a === void 0 ? void 0 : _a.business) === null || _b === void 0 ? void 0 : _b.onFocus) {
                _this.properties.business.onFocus(event, value);
            }
        };
        /**
         * Handle onInput input field
         * This is an arrow function because we want to keep our 'this' context
         * when we pass it to 'addEventListener'
         */
        _this.handleInput = function (event, value) {
            var _a, _b;
            // reset stdErrorKey, otherwise when the same errorKey is appearing again in future
            // brix will not detect a change, since it did not change compared to the old properties
            _this.properties.stdErrorKey = undefined;
            _this.updateMatchedResultItems();
            /* istanbul ignore else */
            if ((_b = (_a = _this.properties) === null || _a === void 0 ? void 0 : _a.business) === null || _b === void 0 ? void 0 : _b.onInput) {
                _this.properties.business.onInput(event, value);
            }
        };
        /**
         * Handle any click on the page
         * Avoids closing of list in case click inside of list (hide the list only on click outside of input field and outside of items list)
         * This is an arrow function because we want to keep our 'this' context
         * when we pass it to 'addEventListener'
         */
        _this.handleGlobalClick = function (event) {
            var isClickedOnList = attempt(function () {
                // `event.target` may be already removed from the DOM at this point, e.g. if the click was triggered on the input's system icon.
                // In this case, `this.containerElement.contains(event.target)` will return false, and the dropdown immediately closes after opening.
                // `event.composedPath()` returns the array of the elements where the event bubbles up, so we rather check this list.
                var itemParents = event.composedPath();
                return itemParents.indexOf(_this.containerElement) > -1;
                // eslint-disable-next-line arrow-body-style
            }, undefined, function () {
                // Fallback if we cannot use `event.composedPath()`.
                // This should happen only in Jest, but that reports good value also in the mentioned above case.
                return _this.containerElement.contains(event.target);
            });
            if (!isClickedOnList) {
                _this.hideResultList();
            }
        };
        /**
         * Handle click on the open/close icon
         * This is an arrow function because we want to keep our 'this' context
         * when we pass it to 'addEventListener'
         */
        _this.handleSystemIconClick = function (event, value) {
            var _a, _b;
            _this.setFormElementFromDOM();
            var isResultListOpened = _this.isFocused();
            if (!isResultListOpened) {
                _this.inputElement.focus();
            }
            else {
                _this.clearOrResetInputValueToPreviousValue();
                _this.resetResultList();
            }
            /* istanbul ignore else */
            if ((_b = (_a = _this.properties) === null || _a === void 0 ? void 0 : _a.business) === null || _b === void 0 ? void 0 : _b.onClickSystemIcon) {
                _this.properties.business.onClickSystemIcon(event, value);
            }
        };
        /**
         * Handle OnKeyDown by input field
         * This is an arrow function because we want to keep our 'this' context
         * when we pass it to 'addEventListener'
         */
        _this.handleKeyDown = function (event, value) {
            var _a, _b;
            // refresh vertical position of result-wrapper since on keydown
            // the validation error message will disappear
            _this.setResultWrapperVerticalPosition();
            switch (event.key) {
                case FORM_SUGGEST_INPUT_EVENT_KEY_ENTER:
                    _this.handleKeyDownEnter(event);
                    break;
                case FORM_SUGGEST_INPUT_EVENT_KEY_ARROW_DOWN:
                    _this.handleKeyDownArrow(event, FORM_SUGGEST_INPUT_EVENT_KEY_ARROW_DOWN);
                    break;
                case FORM_SUGGEST_INPUT_EVENT_KEY_ARROW_UP:
                    _this.handleKeyDownArrow(event, FORM_SUGGEST_INPUT_EVENT_KEY_ARROW_UP);
                    break;
                case FORM_SUGGEST_INPUT_EVENT_KEY_TAB:
                    _this.hideResultList();
                    break;
                case FORM_SUGGEST_INPUT_EVENT_KEY_ESCAPE:
                    // use pressed escape, so we force a value-reset even in desktop viewport
                    _this.clearOrResetInputValueToPreviousValue(true);
                    _this.resetResultList();
                    break;
            }
            /* istanbul ignore else */
            if ((_b = (_a = _this.properties) === null || _a === void 0 ? void 0 : _a.business) === null || _b === void 0 ? void 0 : _b.onKeyDown) {
                _this.properties.business.onKeyDown(event, value);
            }
        };
        return _this;
    }
    /**
     * afterInit (called once after init)
     */
    FormSuggestInput.prototype.afterInit = function () {
        this.initAriaAttributes();
    };
    /**
     * Intentionally return same props. Because no default props to set, but abstract needs implementation
     */
    FormSuggestInput.prototype.getDefaultProperties = function (newProperties) {
        return newProperties;
    };
    FormSuggestInput.prototype.didReceiveProps = function (newProperties, oldProperties) {
        var stdErrorKey = newProperties.stdErrorKey, optState = newProperties.optState;
        if (stdErrorKey !== oldProperties.stdErrorKey) {
            this.handleUpdateFormElementErrorKey(stdErrorKey);
        }
        if (optState !== oldProperties.optState) {
            this.handleUpdateState(optState);
        }
    };
    /**
     * This is an abstract function that every component needs to add event listeners to DOM elements
     */
    FormSuggestInput.prototype.initEvents = function () {
        var _this = this;
        var handleResultElementEvent = function (event, callback) {
            event.stopPropagation();
            var resultItem = event.target.closest(".".concat(FORM_SUGGEST_INPUT_CLASSNAME_RESULT_ITEM));
            /* istanbul ignore if */
            if (!resultItem) {
                // event didnt occure on or within a result item
                return;
            }
            callback(event, resultItem);
        };
        // set event on result element only (event delegation)
        this.resultElement.addEventListener('click', function (event) {
            handleResultElementEvent(event, function (e, resultItem) {
                _this.handleResultItemClick(e, resultItem);
            });
        });
        this.resultElement.addEventListener('mouseover', function (event) {
            handleResultElementEvent(event, function (e, resultItem) {
                _this.handleResultItemMouseOver(e, resultItem);
            });
        });
        this.resultElement.addEventListener('mouseout', function (event) {
            handleResultElementEvent(event, function (e, resultItem) {
                _this.handleResultItemMouseOut(e, resultItem);
            });
        });
    };
    /**
     * Fix a FormSuggestInput-specific, validator-related issue
     */
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    FormSuggestInput.prototype.update = function (newProperties, preventRerender) {
        var _this = this;
        var _a, _b;
        // The validation's `formFieldHandler` function mocks `properties.business.onBlur`
        // and replaces `properties.business.onChange` with an 'empty' function on initialization.
        // This is the required behavior for a normal text input, but in case of FormSuggestInput, we must keep and use `onChange`.
        // `formFieldHandler` does a forced update (preventRerender !=== true) and the new `onChange` does not have any arguments (onChange.length === 0).
        if (preventRerender !== true && ((_b = (_a = newProperties.business) === null || _a === void 0 ? void 0 : _a.onChange) === null || _b === void 0 ? void 0 : _b.length) === 0) {
            newProperties.business.onChange = function (event, value) {
                // `onChange` gets called from the mocked `onBlur` handler, and `onBlur` gets triggered by clicking (or hitting Enter) on one result item.
                // In order to `validateFormField` could read the input's value from `event.currentTarget`, we need to provide the `currentTarget` property,
                // because the target of the original event (`click` or `keydown`) was most probably a result item (<li>).
                Object.defineProperty(event, 'currentTarget', {
                    value: _this.inputElement,
                    writable: false,
                });
                // It is safe to call the mocked `onBlur`, because it will call the original and untouched `onChange` handler.
                newProperties.business.onBlur(event, value);
            };
        }
        _super.prototype.update.call(this, newProperties, preventRerender);
    };
    /**
     * Updates the result items only
     * @param items The new items
     */
    FormSuggestInput.prototype.updateResultList = function (items) {
        this.properties.items = items;
        this.renderResultList();
        this.initResultList(false);
        this.updateMatchedResultItems();
        this.limitResultList();
    };
    /**
     * Clears the input value
     */
    FormSuggestInput.prototype.clearInputValue = function () {
        this.inputElement.value = '';
    };
    /**
     * Renders the result list only with the current items
     */
    FormSuggestInput.prototype.renderResultList = function () {
        this.resultElement.innerHTML = this.renderPartially(this.render, {
            items: this.properties.items,
        }, ".".concat(FORM_SUGGEST_INPUT_CLASSNAME_RESULT_LIST))[0];
    };
    /**
     * Read from DOM
     */
    FormSuggestInput.prototype.readDom = function (formSuggestInputBusinessLogic) {
        var _this = this;
        this.initFormElement();
        this.initResultList();
        var formElementBlockProperties = this.formElementBlockComponent.getProperties() || undefined;
        var items = this.resultItems.map(function (resultItem) {
            var text = _this.getItemTextContent(resultItem);
            var id = _this.getItemId(resultItem) || text;
            var imgIcon = resultItem.getElementsByClassName(FORM_SUGGEST_INPUT_CLASSNAME_RESULT_ITEM_ICON)[0];
            var imgIconSrc = imgIcon === null || imgIcon === void 0 ? void 0 : imgIcon.src;
            var item = {
                id: id,
                text: text,
                imgIconSrc: imgIconSrc,
            };
            return removeUndefinedFieldsFromObject(item);
        });
        this.setIconizedClass(items);
        var properties = __assign(__assign({}, formElementBlockProperties), { items: items, business: formSuggestInputBusinessLogic });
        return removeUndefinedFieldsFromObject(properties);
    };
    /**
     * All rendering gets done with this function. If the component contains another
     * component, you would then render the child component in this function.
     */
    FormSuggestInput.prototype.writeDom = function () {
        this.containerElement.innerHTML = this.render(this.properties);
        this.initFormElement();
        this.initResultList();
        this.setIconizedClass(this.properties.items);
    };
    /**
     * Update CSS classes based on the component's `optState` property
     */
    FormSuggestInput.prototype.handleUpdateState = function (optState) {
        var _a;
        var stateClasses = {
            error: FORM_SUGGEST_INPUT_CLASSNAME_ERROR,
            warn: FORM_SUGGEST_INPUT_CLASSNAME_WARN,
            success: FORM_SUGGEST_INPUT_CLASSNAME_SUCCESS,
        };
        (_a = this.baseElement.classList).remove.apply(_a, __spreadArray([], __read(Object.values(stateClasses)), false));
        if (optState) {
            this.baseElement.classList.add(stateClasses[optState]);
        }
        // error message could have been appeared or removed
        // so we need to re-position the result-wrapper
        this.setResultWrapperVerticalPosition();
        this.formElementBlockComponent.update({ optState: optState }, true);
    };
    /**
     * Update the input field based on the validator
     */
    FormSuggestInput.prototype.handleUpdateFormElementErrorKey = function (stdErrorKey) {
        var validationStyle = this.properties.optWarnForValidation ? 'warn' : 'error';
        var optState = stdErrorKey ? validationStyle : undefined;
        this.update({ optState: optState }, true);
        this.formElementBlockComponent.update({ stdErrorKey: stdErrorKey }, true);
    };
    /**
     * Update the input field system icon
     */
    FormSuggestInput.prototype.handleUpdateFormElementSystemIcon = function (optSystemIcon) {
        var containerInputProps = this.formElementBlockComponent.getPropertyByKey('containerInput');
        this.formElementBlockComponent.update({
            containerInput: __assign(__assign({}, containerInputProps), { optSystemIcon: optSystemIcon }),
        }, true);
    };
    /**
     * Called when the user selects one item of the result list
     */
    FormSuggestInput.prototype.handleSelect = function (event, resultItem) {
        var _a, _b, _c;
        /* istanbul ignore else */
        if ((_b = (_a = this.properties) === null || _a === void 0 ? void 0 : _a.business) === null || _b === void 0 ? void 0 : _b.onSelect) {
            var resultItemId_1 = this.getItemId(resultItem);
            var resultItemText_1 = this.getItemTextContent(resultItem);
            var itemProps = (_c = this.properties.items) === null || _c === void 0 ? void 0 : _c.find(function (item) { return item.id === resultItemId_1 || item.text === resultItemText_1; });
            this.properties.business.onSelect(event, itemProps);
        }
    };
    /**
     * Updates the result items
     */
    FormSuggestInput.prototype.updateMatchedResultItems = function () {
        // ignore special chars in user input
        this.userInputValue = this.inputElement.value.replace(/[*|":<>[\]{}`\\()';@&$]/gi, '').trim();
        var inputValue = this.userInputValue.toLowerCase();
        for (var i = 0, iLen = this.resultItems.length; i < iLen; i++) {
            var itemElement = this.resultItems[i];
            var itemTextElement = itemElement.getElementsByTagName('span')[0];
            var itemText = this.getItemTextContent(itemTextElement);
            var itemTextLowerCase = itemText.toLowerCase();
            // in case it was selected by key down
            this.setResultItemActiveState(itemElement, false);
            if (inputValue.length > 0) {
                // filled value = show only those items which match
                var isInputTextMatched = itemTextLowerCase.search(new RegExp(inputValue.replace(/\s+/, '|'))) !== -1;
                if (isInputTextMatched) {
                    itemElement.classList.remove(CLASSNAME_HIDDEN);
                    itemTextElement.innerHTML = this.highlightMatchedText(itemText, inputValue);
                }
                else if (!this.properties.optDisableFiltering) {
                    itemElement.classList.add(CLASSNAME_HIDDEN);
                }
            }
            else {
                // empty value = show all items
                itemElement.classList.remove(CLASSNAME_HIDDEN);
                itemTextElement.innerHTML = this.getItemTextContent(itemTextElement);
            }
        }
        this.matchedResultItems = this.resultItems.filter(function (element) { return !element.classList.contains(CLASSNAME_HIDDEN); });
    };
    /**
     * Handle mouse over on result item
     */
    FormSuggestInput.prototype.handleResultItemMouseOver = function (event, resultItem) {
        // remove item's class for existing active item
        var selectedItem = this.getActiveResultItem();
        if (selectedItem) {
            this.setResultItemActiveState(selectedItem, false);
        }
        this.setResultItemActiveState(resultItem, true);
    };
    /**
     * Handle mouse out on result item
     */
    FormSuggestInput.prototype.handleResultItemMouseOut = function (event, resultItem) {
        this.setResultItemActiveState(resultItem, false);
    };
    /**
     * Handle click on result item
     */
    FormSuggestInput.prototype.handleResultItemClick = function (event, resultItem) {
        event.stopPropagation();
        var newValue = this.getItemTextContent(resultItem);
        this.inputElement.value = newValue;
        this.hideResultList();
        this.resetResultItems();
        this.matchedResultItems = this.resultItems;
        this.handleChange(event, newValue);
        this.handleSelect(event, resultItem);
    };
    /**
     * Handle onKeyEnter input field
     */
    FormSuggestInput.prototype.handleKeyDownEnter = function (event) {
        var inputValue = this.inputElement.value.trim();
        var activeItem;
        if (inputValue) {
            // if we have a non-empty value check and the user pressed 'enter'
            // check if there is one item with exactly the same text content
            // as the text the user typed (case insensitive)
            var matchingResultItem = this.getMatchingResultItemsByText(inputValue);
            if (matchingResultItem) {
                // if one matching item was found, update the input value
                // to the non-lowercase version of the item text
                var itemText = this.getItemTextContent(matchingResultItem);
                this.inputElement.value = itemText;
                activeItem = matchingResultItem;
            }
        }
        if (!activeItem) {
            // if we didnt find the active item by comparing the text content
            // find it by  the 'active' class
            activeItem = this.getActiveResultItem();
        }
        this.inputElement.blur();
        this.hideResultList();
        this.resetResultItems();
        this.matchedResultItems = this.resultItems;
        this.handleChange(event, inputValue);
        activeItem && this.handleSelect(event, activeItem);
    };
    /**
     * Handle onKeyArrow input field
     * @param e Event object
     * @param direction string, value: ArrowDown | ArrowUp
     */
    FormSuggestInput.prototype.handleKeyDownArrow = function (e, direction) {
        e.preventDefault();
        var preselectedIndex;
        var nextActiveItem;
        var activeItemIndex = this.getActiveResultItemIndex();
        if (activeItemIndex >= 0) {
            preselectedIndex = activeItemIndex;
            var nextIndex = FORM_SUGGEST_INPUT_EVENT_KEY_ARROW_DOWN === direction ? activeItemIndex + 1 : activeItemIndex - 1;
            var activeItem = this.matchedResultItems[activeItemIndex];
            this.setResultItemActiveState(activeItem, false);
            // in case of mooving from first item up or from last item down nextActiveItem is undefined
            nextActiveItem = this.matchedResultItems[nextIndex] || undefined;
        }
        else {
            // in case no item is preselected
            nextActiveItem = FORM_SUGGEST_INPUT_EVENT_KEY_ARROW_DOWN === direction
                ? this.matchedResultItems[0]
                : this.matchedResultItems[this.matchedResultItems.length - 1];
        }
        // in case of scrollbar by result items
        if (this.baseElement.classList.contains(FORM_SUGGEST_INPUT_CLASSNAME_RESULT_LIST_LIMITED) && nextActiveItem) {
            this.keepResultItemInViewport(nextActiveItem, direction, preselectedIndex);
        }
        this.selectItem(nextActiveItem);
    };
    /**
     * Checks if one of the matched result items exactly matches
     * the given value in a lowercase comparison and returns this item
     * or null if no element matches.
     * @param value The string value
     * @returns The matching LI element or null if no element matches
     */
    FormSuggestInput.prototype.getMatchingResultItemsByText = function (value) {
        var _this = this;
        var result = this.matchedResultItems.find(function (resultItem) {
            return _this.getItemTextContent(resultItem).toLowerCase() === value.toLowerCase();
        }) || null;
        return result;
    };
    /**
     * Keep selected by arrow key item in viewport in case scrollbar is there
     * @param item HTMLElement
     * @param direction ArrowKeyDirection
     * @param preselectedIndex number, value: index of already selected item or undefined in case no one item is already selected (first action after focus)
     */
    FormSuggestInput.prototype.keepResultItemInViewport = function (item, direction, preselectedIndex) {
        var resultElementRect = this.resultElement.getBoundingClientRect();
        var itemRect = item.getBoundingClientRect();
        var resultTop = Math.round(resultElementRect.top);
        var resultBottom = Math.round(resultElementRect.bottom);
        var itemTop = Math.round(itemRect.top);
        var itemBottom = Math.round(itemRect.bottom);
        switch (direction) {
            case FORM_SUGGEST_INPUT_EVENT_KEY_ARROW_DOWN:
                if (!preselectedIndex) {
                    this.resultElement.scrollTop = 0;
                }
                else if (itemBottom > resultBottom) {
                    this.resultElement.scrollTop += item.scrollHeight;
                }
                break;
            case FORM_SUGGEST_INPUT_EVENT_KEY_ARROW_UP:
                if (!preselectedIndex) {
                    this.resultElement.scrollTop = this.resultElement.scrollHeight;
                }
                else if (itemTop < resultTop) {
                    this.resultElement.scrollTop -= item.scrollHeight;
                }
                break;
            default: // in case reopen result list after item selection
                if (itemBottom > resultBottom) {
                    var scrollTopOffset = item.scrollHeight * (preselectedIndex + 1 - FORM_SUGGEST_INPUT_MAX_ITEMS_BEFORE_SCROLLBAR);
                    this.resultElement.scrollTop += scrollTopOffset;
                }
                break;
        }
    };
    /**
     * Returns the ID of the given result item
     */
    FormSuggestInput.prototype.getItemId = function (item) {
        return item.dataset.id;
    };
    /**
     * Returns the text content of the given result item
     */
    FormSuggestInput.prototype.getItemTextContent = function (item) {
        return item.textContent.trim();
    };
    /**
     * Select result item by using key board
     * @param item HTMLElement
     */
    FormSuggestInput.prototype.selectItem = function (item) {
        if (item) {
            this.setResultItemActiveState(item, true);
            this.inputElement.value = this.getItemTextContent(item);
        }
        else {
            this.inputElement.value = this.userInputValue;
        }
    };
    /**
     * Handle on show result list
     */
    FormSuggestInput.prototype.showResultList = function () {
        if (this.removeLimitedClassTimeoutId) {
            clearTimeout(this.removeLimitedClassTimeoutId);
            this.removeLimitedClassTimeoutId = null;
        }
        this.setResultWrapperVerticalPosition();
        this.handleUpdateFormElementSystemIcon(FORM_SUGGEST_INPUT_SYSTEM_ICON_WHEN_OPENED);
        this.setFocusedState(true);
        this.setAriaExpanded(true);
        var inputValue = this.inputElement.value.trim();
        // Save the current value, to be able to restore it when hitting Esc or clicking the X icon (for overlay case)
        this.inputPrevValue = inputValue;
        if (this.isMobileOnlyViewport()) {
            // Save scroll position (because we are going to prevent the body from scrolling)
            this.scrollPosition = window.scrollY;
            document.body.classList.add(CLASSNAME_NO_SCROLL);
            this.mobileDevicePositionFixedCssWorkaround(this.baseElement);
        }
        else {
            var doScrollItemInViewPort = this.limitResultList();
            // highlight the preselected item
            if (inputValue.length > 0) {
                this.highlightPreselectedItem(inputValue, doScrollItemInViewPort);
            }
        }
        // select input value
        if (inputValue.length > 0) {
            this.inputElement.select();
        }
        if (!this.isMobileOnlyViewport()) {
            document.addEventListener('click', this.handleGlobalClick);
        }
    };
    /**
     * Checks if the result list needs to be limited
     * with a scrollbar because there are too many results.
     * If it detects a scrollbar is needed, it will set the
     * 'limited' class.
     * @returns True if the result list was limited, else false
     */
    FormSuggestInput.prototype.limitResultList = function () {
        // set class for scroll bar in case items list is longer as MAX_ITEMS_BEFORE_SCROLLBAR
        if (this.matchedResultItems.length > FORM_SUGGEST_INPUT_MAX_ITEMS_BEFORE_SCROLLBAR) {
            this.baseElement.classList.add(FORM_SUGGEST_INPUT_CLASSNAME_RESULT_LIST_LIMITED);
            return true;
        }
        return false;
    };
    /**
     * Handle on hide result list
     */
    FormSuggestInput.prototype.hideResultList = function () {
        var _this = this;
        this.handleUpdateFormElementSystemIcon(FORM_SUGGEST_INPUT_SYSTEM_ICON_WHEN_CLOSED);
        this.setFocusedState(false);
        this.setAriaExpanded(false);
        if (this.isMobileOnlyViewport()) {
            document.body.classList.remove(CLASSNAME_NO_SCROLL);
            // Restore scroll position
            window.scroll(0, this.scrollPosition);
        }
        else {
            document.removeEventListener('click', this.handleGlobalClick);
        }
        // due to css transition
        this.removeLimitedClassTimeoutId = setTimeout(function () {
            _this.removeLimitedClassTimeoutId = null;
            _this.baseElement.classList.remove(FORM_SUGGEST_INPUT_CLASSNAME_RESULT_LIST_LIMITED);
        }, 200);
    };
    /**
     * Closes the result list and restores the input values as it was before opening the dropdown
     * @private
     */
    FormSuggestInput.prototype.resetResultList = function () {
        this.inputElement.blur();
        this.hideResultList();
        this.resetResultItems();
        this.matchedResultItems = this.resultItems;
    };
    /**
     * For viewports >= 'small' this method clears the input value (non-overlay case).
     * For viewports < 'small' this method resets the value to previous value (overlay case).
     * @param forceReset If true, this param forces a value reset to the previous value,
     * even if the viewport is not < 'small'.
     */
    FormSuggestInput.prototype.clearOrResetInputValueToPreviousValue = function (forceReset) {
        if (forceReset === void 0) { forceReset = false; }
        if (this.isMobileOnlyViewport() || forceReset) {
            // reset value to prev value (in overlay case)
            if (this.inputElement.value !== this.inputPrevValue) {
                this.inputElement.value = this.inputPrevValue;
                this.handleChange(new Event('change'), this.inputPrevValue);
            }
        }
        else {
            // clear value (in non-overlay case)
            var newValue = '';
            if (this.inputElement.value !== newValue) {
                this.inputElement.value = newValue;
                this.handleChange(new Event('change'), newValue);
            }
        }
    };
    /**
     * Updates the result wrapper's `top` CSS property
     */
    FormSuggestInput.prototype.setResultWrapperVerticalPosition = function () {
        var _a, _b;
        /* istanbul ignore if */
        if (!this.resultWrapperElement) {
            return;
        }
        var formElementBlockFooterHeight = ((_a = this.helperTextElement) === null || _a === void 0 ? void 0 : _a.scrollHeight) || ((_b = this.errorMessageElement) === null || _b === void 0 ? void 0 : _b.scrollHeight);
        this.resultWrapperElement.style.marginTop = "-".concat(formElementBlockFooterHeight.toString(), "px");
    };
    /**
     * In case the input value is not empty
     * @param inputValue string
     * @param scrollItemInViewPort boolean, action: scroll preselected item in viewport
     */
    FormSuggestInput.prototype.highlightPreselectedItem = function (inputValue, scrollItemInViewPort) {
        var x = this.resultItems.length;
        for (var i = 0; i < x; i++) {
            var itemElement = this.resultItems[i];
            var itemText = itemElement.getElementsByTagName('span')[0].textContent.trim();
            if (inputValue === itemText) {
                this.setResultItemActiveState(itemElement, true);
                scrollItemInViewPort && this.keepResultItemInViewport(itemElement, undefined, i);
            }
        }
    };
    /**
     * Reset result items
     */
    FormSuggestInput.prototype.resetResultItems = function () {
        var x = this.resultItems.length;
        for (var i = 0; i < x; i++) {
            var itemElement = this.resultItems[i];
            this.setResultItemActiveState(itemElement, false);
            itemElement.classList.remove(CLASSNAME_HIDDEN); // remove item's class for hiding
            this.resetItemText(itemElement); // remove item's text formatting
        }
    };
    /**
     * Reset item text (remove formatting)
     */
    FormSuggestInput.prototype.resetItemText = function (item) {
        var itemTextElement = item.getElementsByTagName('span')[0];
        itemTextElement.innerHTML = this.getItemTextContent(itemTextElement);
    };
    /**
     * Make the matched part of text bold
     */
    FormSuggestInput.prototype.highlightMatchedText = function (text, needle) {
        return text.replace(new RegExp("(".concat(needle, ")"), 'ig'), '<b>$1</b>');
    };
    /**
     * Returns an the business object used for the FormElement
     */
    FormSuggestInput.prototype.getFormElementBusiness = function () {
        var business = {
            onChange: this.handleChange,
            onBlur: this.handleBlur,
            onFocus: this.handleFocus,
            onInput: this.handleInput,
            onKeyDown: this.handleKeyDown,
            onClickSystemIcon: this.handleSystemIconClick,
        };
        return business;
    };
    /**
     * Init and render input field
     */
    FormSuggestInput.prototype.initFormElement = function () {
        var formElementBlockProperties = this.getFormElementBusiness();
        this.baseElement = this.containerElement.getElementsByClassName(FORM_SUGGEST_INPUT_BASE_CLASSNAME)[0];
        var inputWrapperElement = this.containerElement
            .getElementsByClassName(FORM_SUGGEST_INPUT_CLASSNAME_INPUT_CONTAINER)[0];
        this.formElementBlockComponent = createFormElement(inputWrapperElement, formElementBlockProperties);
        this.userInputValue = '';
        var parsedFormElementProperties = this.formElementBlockComponent.getProperties();
        if (parsedFormElementProperties.optDisabled) {
            this.baseElement.classList.add(FORM_SUGGEST_INPUT_CLASSNAME_INPUT_DISABLED);
        }
        this.setFormElementFromDOM();
    };
    /**
     * Input field gets rerendered completely in initialization when using validator, here we set the new one
     */
    FormSuggestInput.prototype.setFormElementFromDOM = function () {
        this.inputElement = this.containerElement
            .getElementsByClassName(FORM_TEXT_INPUT_INPUT_CLASSNAME)[0];
        this.helperTextElement = this.containerElement
            .getElementsByClassName(FORM_ELEMENT_BLOCK_HELPER_TEXT_CLASSNAME)[0];
        this.errorMessageElement = this.containerElement
            .getElementsByClassName(FORM_ELEMENT_BLOCK_ERROR_MESSAGE_CLASSNAME)[0];
    };
    /**
     * Init result list
     * @param shouldUpdateIcon Control if the system icon should be updated or not
     */
    FormSuggestInput.prototype.initResultList = function (shouldUpdateIcon) {
        if (shouldUpdateIcon === void 0) { shouldUpdateIcon = true; }
        if (shouldUpdateIcon) {
            this.handleUpdateFormElementSystemIcon(FORM_SUGGEST_INPUT_SYSTEM_ICON_WHEN_CLOSED);
        }
        this.resultWrapperElement = this.baseElement
            .getElementsByClassName(FORM_SUGGEST_INPUT_CLASSNAME_RESULT_WRAPPER)[0];
        this.resultElement = this.containerElement.getElementsByClassName(FORM_SUGGEST_INPUT_CLASSNAME_RESULT_LIST)[0];
        this.resultItems = Array.from(this.resultElement.getElementsByClassName(FORM_SUGGEST_INPUT_CLASSNAME_RESULT_ITEM));
        this.matchedResultItems = this.resultItems;
        this.baseElement.classList.toggle(FORM_SUGGEST_INPUT_CLASSNAME_HAS_RESULTS, !!this.resultItems.length);
    };
    /**
     * Even if only one of all items has icon, the resultElement get additional class
     */
    FormSuggestInput.prototype.setIconizedClass = function (items) {
        var hasItemWithIcon = (items === null || items === void 0 ? void 0 : items.find(function (item) { return item.imgIconSrc; })) !== undefined;
        this.baseElement.classList.toggle(FORM_SUGGEST_INPUT_CLASSNAME_RESULT_LIST_ICONIZED, hasItemWithIcon);
    };
    /**
     * on some iPhones, position: fixed and top: 0 puts the
     * div outside the viewport. This is a workaround for this
     */
    FormSuggestInput.prototype.mobileDevicePositionFixedCssWorkaround = function (item) {
        window.setTimeout(function () {
            item.scrollIntoView(true);
        }, 200);
    };
    /**
     * Sets the focused state on the baseElement
     * @param isFocused The focused state
     */
    FormSuggestInput.prototype.setFocusedState = function (isFocused) {
        this.baseElement.classList.toggle(FORM_SUGGEST_INPUT_CLASSNAME_INPUT_FOCUSED, isFocused);
    };
    /**
     * Checks if the baseElement has focused state
     */
    FormSuggestInput.prototype.isFocused = function () {
        return this.baseElement.classList.contains(FORM_SUGGEST_INPUT_CLASSNAME_INPUT_FOCUSED);
    };
    /**
     * Sets the active-state of the given element
     * @param resultItem The element
     * @param isActive The active state
     */
    FormSuggestInput.prototype.setResultItemActiveState = function (resultItem, isActive) {
        resultItem.classList.toggle(FORM_SUGGEST_INPUT_CLASSNAME_RESULT_ITEM_ACTIVE, isActive);
        this.setAriaActiveDescendant(isActive ? resultItem.id : '');
    };
    /**
     * Returns the active-state of the given element
     * @param resultItem The element
     */
    FormSuggestInput.prototype.isResultItemActive = function (resultItem) {
        return resultItem.classList.contains(FORM_SUGGEST_INPUT_CLASSNAME_RESULT_ITEM_ACTIVE);
    };
    /**
     * Returns the currently active item or null
     */
    FormSuggestInput.prototype.getActiveResultItem = function () {
        var _this = this;
        return this.matchedResultItems.find(function (item) { return _this.isResultItemActive(item); }) || null;
    };
    /**
     * Returns the index of the active result item
     */
    FormSuggestInput.prototype.getActiveResultItemIndex = function () {
        var _this = this;
        return this.matchedResultItems.findIndex(function (item) { return _this.isResultItemActive(item); });
    };
    /**
     * Initializes the components ARIA attributes
     */
    FormSuggestInput.prototype.initAriaAttributes = function () {
        this.setAriaExpanded(false);
        this.setAriaActiveDescendant('');
    };
    /**
     * Sets the aria-expanded attribute on the input element
     * @param value The value
     */
    FormSuggestInput.prototype.setAriaExpanded = function (value) {
        this.inputElement.setAttribute('aria-expanded', value.toString());
    };
    /**
     * Sets the aria-activedescendant attribute on the input element
     * @param value The value
     */
    FormSuggestInput.prototype.setAriaActiveDescendant = function (value) {
        this.inputElement.setAttribute('aria-activedescendant', value);
    };
    /**
     * Checks if the current viewport is mobile only (< small)
     */
    FormSuggestInput.prototype.isMobileOnlyViewport = function () {
        return getViewportName() === MOBILE_ONLY;
    };
    /**
     * Toggles the visibility of the loading animation
     * @param showOrHide True to show, false to hide
     */
    FormSuggestInput.prototype.toggleLoadingAnimation = function (showOrHide) {
        this.formElementBlockComponent.toggleLoadingAnimation(showOrHide);
    };
    return FormSuggestInput;
}(Pattern));
export { FormSuggestInput };
/**
 * All components should have a factory function to create the component
 * This function returns an instance of FormSuggestInput
 */
export var createFormSuggestInput = function (containerElement, businessLogicOrProperties) {
    var formSuggestInput = new FormSuggestInput(containerElement, businessLogicOrProperties);
    formSuggestInput.init();
    return formSuggestInput;
};
