var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import './offer-price.scss';
import offerPriceTemplate from './_offer-price.hbs';
import { Pattern, removeUndefinedFieldsFromObject, NO_PATTERN_BUSINESS_LOGIC, } from '@vfde-brix/ws10/core';
import { toggleElementVisibility } from '../core/utils/toggleElementVisibility';
import { OFFER_PRICE_ADDITIONAL_TEXT_CLASSNAME, OFFER_PRICE_BUTTONS_CLASSNAME, OFFER_PRICE_HIGHLIGHT_BADGE_CLASSNAME, OFFER_PRICE_HEADLINE_CLASSNAME, OFFER_PRICE_LEGAL_TEXT_CLASSNAME, OFFER_PRICE_PRICES_CLASSNAME, OFFER_PRICE_SUBLINE_TEXT_CLASSNAME, OFFER_PRICE_PRICE_CLASSNAME, OFFER_PRICE_BUTTON_CLASSNAME, } from './Constants';
import { createHeadline, HEADLINE_BASE_CLASSNAME, } from '@vfde-brix/ws10/headline';
import { createHighlightBadge, HIGHLIGHT_BADGE_BASE_CLASSNAME, } from '@vfde-brix/ws10/highlight-badge';
import { createPromoPrice, PROMO_PRICE_BASE_CLASSNAME, } from '@vfde-brix/ws10/promo-price';
import { BUTTON_BASE_CLASSNAME, createButton, } from '@vfde-brix/ws10/button';
import { CLASSNAME_HIDDEN } from '../styles';
import { isEqual } from 'lodash';
/**
 * OfferPrice class
 */
var OfferPrice = /** @class */ (function (_super) {
    __extends(OfferPrice, _super);
    /**
     * OfferPrice constructor
     */
    function OfferPrice(containerElement, properties) {
        var _this = _super.call(this, containerElement, properties) || this;
        _this.highlightBadge = null;
        _this.headline = null;
        _this.promoPrices = [];
        _this.buttons = [];
        return _this;
    }
    /**
     * Intentionally return same props because no props to get defaults
     */
    OfferPrice.prototype.getDefaultProperties = function (newProperties) {
        return newProperties;
    };
    /**
     * Write DOM
     */
    OfferPrice.prototype.writeDom = function () {
        this.containerElement.innerHTML = offerPriceTemplate(this.properties);
    };
    /**
     * Inits all child components and assigns
     * them to the class properties.
     * Called after writeDom and before readDom.
     */
    OfferPrice.prototype.initDom = function (isWriteDom) {
        var _this = this;
        var _a, _b;
        // Mount single-container-components
        this.highlightBadge = this.mountHighlightBadge(isWriteDom, (_a = this.properties) === null || _a === void 0 ? void 0 : _a.containerHighlightBadge);
        this.headline = this.mountHeadline(isWriteDom, (_b = this.properties) === null || _b === void 0 ? void 0 : _b.containerHeadline);
        // Mount multi-container-components.
        // Why we pass the business objects only to the mounting methods for multi-container-components:
        // Multi-Container components are always mounted using 'readDom'. This is because as soon
        // as a component is added or removed the OfferPrice re-renders all components of that kind
        // using Handlebars partial rendering. After that, we just apply the business logic from our code.
        // That is also the reason, why these methods don't have a 'writeDom' param because they are always mounted using 'readDom'.
        this.promoPrices = this.mountPromoPrices(this.getPriceContainers()
            .map(function (priceContainer, i) { var _a, _b, _c, _d; return (_d = (_c = (_b = (_a = _this.properties) === null || _a === void 0 ? void 0 : _a.containerPromoPrices) === null || _b === void 0 ? void 0 : _b[i]) === null || _c === void 0 ? void 0 : _c.business) !== null && _d !== void 0 ? _d : NO_PATTERN_BUSINESS_LOGIC; }));
        this.buttons = this.mountButtons(this.getButtonContainers()
            .map(function (buttonContainer, i) { var _a, _b, _c, _d; return (_d = (_c = (_b = (_a = _this.properties) === null || _a === void 0 ? void 0 : _a.containerButtons) === null || _b === void 0 ? void 0 : _b[i]) === null || _c === void 0 ? void 0 : _c.business) !== null && _d !== void 0 ? _d : NO_PATTERN_BUSINESS_LOGIC; }));
    };
    /**
     * Read properties from DOM
     */
    OfferPrice.prototype.readDom = function (business) {
        var _a, _b;
        var sublineTextContainer = this.getSublineTextContainer();
        var legalTextContainer = this.getLegalTextContainer();
        var additionalTextContainer = this.getAdditionalTextContainer();
        var stdSubline = sublineTextContainer.textContent.trim();
        var txtLegalText = Array.from(legalTextContainer.children).map(function (child) { return child === null || child === void 0 ? void 0 : child.innerHTML; });
        var txtAdditionalText = Array.from(additionalTextContainer.children).map(function (child) { return child === null || child === void 0 ? void 0 : child.innerHTML; });
        var containerHighlightBadge = (_a = this.highlightBadge) === null || _a === void 0 ? void 0 : _a.getProperties();
        var containerHeadline = (_b = this.headline) === null || _b === void 0 ? void 0 : _b.getProperties();
        var containerPromoPrices = this.promoPrices.map(function (promoPrice) { return promoPrice.getProperties(); });
        var containerButtons = this.buttons.map(function (button) { return button.getProperties(); });
        var offerPriceProperties = {
            stdSubline: stdSubline,
            txtLegalText: txtLegalText,
            txtAdditionalText: txtAdditionalText,
            containerHighlightBadge: containerHighlightBadge,
            containerHeadline: containerHeadline,
            containerPromoPrices: containerPromoPrices,
            containerButtons: containerButtons,
            business: business,
        };
        return removeUndefinedFieldsFromObject(offerPriceProperties);
    };
    /**
     * Not implemented update method.
     * Use invidual update methods instead.
     * @throws Error
     */
    OfferPrice.prototype.update = function () {
        throw new Error('Not implemented. Use invidual update methods instead.');
    };
    // endregion
    // #endregion
    // #region Component mounting (VSCode)
    // region Component mounting (Webstorm)
    /**
     * Mounts the highlightBadge
     * @param isWriteDom If true, the component will be mounted dynamically (writeDom), otherwise statically (readDom)
     * @param highlightBadgeProperties The highlightBadge properties (required when writeDom=true)
     */
    OfferPrice.prototype.mountHighlightBadge = function (isWriteDom, highlightBadgeProperties) {
        return this.mountSingleContainerComponent(this.getHighlightBadgeContainer(), HIGHLIGHT_BADGE_BASE_CLASSNAME, isWriteDom, createHighlightBadge, isWriteDom ? highlightBadgeProperties : NO_PATTERN_BUSINESS_LOGIC);
    };
    /**
     * Mounts the headline
     * @param isWriteDom If true, the component will be mounted dynamically (writeDom), otherwise statically (readDom)
     * @param headlineProperties The headline properties (required when writeDom=true)
     */
    OfferPrice.prototype.mountHeadline = function (isWriteDom, headlineProperties) {
        return this.mountSingleContainerComponent(this.getHeadlineContainer(), HEADLINE_BASE_CLASSNAME, isWriteDom, createHeadline, isWriteDom ? headlineProperties : NO_PATTERN_BUSINESS_LOGIC);
    };
    /**
     * Mounts the component in a container which can only include
     * a single component (i. e. highlightBadge and headline)
     * @param containerElement The containerElement
     * @param componentClassName The class name of the component element within containerElement
     * @param writeDom If true, the component will be mounted dynamically (writeDom), otherwise statically (readDom)
     * @param factory The component factory function
     * @param propertiesOrBusiness The component properties (required when writeDom=true) or business (required when writeDom=false)
     */
    OfferPrice.prototype.mountSingleContainerComponent = function (containerElement, componentClassName, writeDom, factory, propertiesOrBusiness) {
        var component;
        if (writeDom) {
            // mount component dynamically using 'writeDom'
            component = propertiesOrBusiness && factory(containerElement, propertiesOrBusiness);
        }
        else {
            // mount component statically using 'readDom'
            var componentElement = containerElement.getElementsByClassName(componentClassName)[0];
            component = componentElement && factory(componentElement.parentElement, propertiesOrBusiness);
        }
        return component;
    };
    /**
     * Mounts the promoPrices
     * @param promoPriceBusinessObjects The promoPrice business objects
     */
    OfferPrice.prototype.mountPromoPrices = function (promoPriceBusinessObjects) {
        return this.mountMultiContainerComponents(this.getPriceContainers(), PROMO_PRICE_BASE_CLASSNAME, createPromoPrice, promoPriceBusinessObjects);
    };
    /**
     * Mounts the buttons
     * @param buttonBusinessObjects The button business objects
     */
    OfferPrice.prototype.mountButtons = function (buttonBusinessObjects) {
        return this.mountMultiContainerComponents(this.getButtonContainers(), BUTTON_BASE_CLASSNAME, createButton, buttonBusinessObjects);
    };
    /**
     * Mounts the components in a container which may
     * include multiple components (i. e. promoPrices and buttons).
     * This mounting is always done using 'readDom'.
     * That's why the function accepts the business object only and not the full properties.
     * @param containerElements Array of containerElements
     * @param componentClassName The class name of the component element within containerElement
     * @param factory The component factory function
     * @param businessObjects The components business objects
     */
    OfferPrice.prototype.mountMultiContainerComponents = function (containerElements, componentClassName, factory, businessObjects) {
        var components = [];
        containerElements.forEach(function (containerElement, i) {
            var componentElement = containerElement.getElementsByClassName(componentClassName)[0];
            var component = componentElement && factory(componentElement.parentElement, businessObjects[i]);
            components.push(component);
        });
        return components;
    };
    // /**
    //  * Helper method to reduce code duplication.
    //  * It returns the element with the className from within the container
    //  * @param container
    //  * @param className
    //  * @returns
    //  */
    // private getComponentElement (container: Element, className: string) {
    //     return container.getElementsByTagName(className)[0];
    // }
    // endregion
    // #endregion
    // #region Component updating (VSCode)
    // region Component updating (Webstorm)
    /**
     * Updates the highlightBadge individually
     */
    OfferPrice.prototype.updateHighlightBadge = function (newHighlightBadgeProperties) {
        this.updateSingleContainerComponent(this.getHighlightBadgeContainer.bind(this), newHighlightBadgeProperties, 'containerHighlightBadge', 'highlightBadge', this.mountHighlightBadge.bind(this));
    };
    /**
     * Updates the headline individually
     */
    OfferPrice.prototype.updateHeadline = function (newHeadlineProperties) {
        this.updateSingleContainerComponent(this.getHeadlineContainer.bind(this), newHeadlineProperties, 'containerHeadline', 'headline', this.mountHeadline.bind(this));
    };
    /**
     * Updates the component in a container which can only include
     * a single component (i. e. highlightBadge and headline)
     * @param getContainerElement A function which returns the containerElement
     * @param newProperties The new component properties for update
     * @param propertiesPropertyName The name of the properties property
     * @param componentPropertyName The name of the component property
     * @param mountingMethod The method to mount the component
     */
    OfferPrice.prototype.updateSingleContainerComponent = function (getContainerElement, newProperties, propertiesPropertyName, componentPropertyName, mountingMethod) {
        var container = getContainerElement();
        container.classList.toggle(CLASSNAME_HIDDEN, !newProperties);
        if (!newProperties) {
            // remove component props and clear container content
            this.properties[propertiesPropertyName] = newProperties;
            container.textContent = '';
            return;
        }
        // update component
        if (this[componentPropertyName]) {
            // update existing component
            this[componentPropertyName].update(newProperties);
        }
        else {
            // render new component
            this[componentPropertyName] = mountingMethod(true, newProperties);
        }
        // backsync the properties of the component to the offer price properties
        this.properties[propertiesPropertyName] = this[componentPropertyName].getProperties();
    };
    /**
     * Updates the promoPrices individually or all promoPrices at once (if the number of promoPrices changed).
     * Please note: you might have to pass a full `IPromoPriceProperties` object if you render new promoPrices.
     */
    OfferPrice.prototype.updatePromoPrices = function (newPromoPricesProperties) {
        this.updateMultiContainerComponent(this.getPricesContainer.bind(this), newPromoPricesProperties, 'containerPromoPrices', 'promoPrices', OFFER_PRICE_PRICES_CLASSNAME, this.mountPromoPrices.bind(this));
    };
    /**
     * Updates the buttons individually or all buttons at once (if the number of buttons changed).
     * Please note: you might have to pass a full `IButtonProperties` object if you render new buttons.
     */
    OfferPrice.prototype.updateButtons = function (newButtonsProperties) {
        this.updateMultiContainerComponent(this.getButtonsContainer.bind(this), newButtonsProperties, 'containerButtons', 'buttons', OFFER_PRICE_BUTTONS_CLASSNAME, this.mountButtons.bind(this));
    };
    /**
     * Updates the components in a container which may
     * include multiple components (i. e. promoPrices and buttons)
     * @param getContainerElement A function which returns the containerElement
     * @param newProperties The new properties
     * @param propertiesPropertyName The name of the properties property
     * @param componentsPropertyName The name of the components property
     * @param containerClassName The class name of the container in the offer-price in which the update is done
     * @param mountingMethod The method to mount the component
     */
    OfferPrice.prototype.updateMultiContainerComponent = function (getContainerElement, newProperties, propertiesPropertyName, componentsPropertyName, containerClassName, mountingMethod) {
        var _a;
        var container = getContainerElement();
        var existingProperties = this.properties[propertiesPropertyName];
        // All components will be removed, if either `newProperties` itself is falsy (e.g. `null`)
        // or if newProperties is an array of falsy values (e.g. `null[]`) which has the same length
        // as the existing properties which means 'remove all'.
        // This means passing `null` or e. g. `[null, null]` will behave exactly the same.
        var shouldRemoveAllComponents = !newProperties
            || (existingProperties
                && existingProperties.length
                && existingProperties.length === newProperties.length
                && newProperties.every(function (props) { return !props; }));
        container.classList.toggle(CLASSNAME_HIDDEN, shouldRemoveAllComponents);
        if (shouldRemoveAllComponents) {
            // remove components
            this.properties[propertiesPropertyName] = null;
            container.textContent = '';
            return;
        }
        // update components
        // Components will be updated invididually if the length of the newProperties array
        // is the same or less than the existingProperties array.
        // If you have two components, and want to update both components, you should pass
        // `[{ stdFoo: '' }, { stdFoo: '' }]` to the update method.
        // If you only want to update the first component, you should pass
        // `[{ stdFoo: '' }]` to the update method.
        // If you only want to update the second component, you should pass
        // `[{}, { stdFoo: '' }]` to the update method.
        // A single component can be removed by passing `null` in the array.
        // Please note, that this will re-render and re-mount all child components.
        // If you want to remove component 1, you should pass
        // `[null] to the update method.
        // If you want to remove component 2, you should pass
        // `[{}, null] to the update method.
        // If you want to remove component 1 and update component 2, you should pass
        // `[null, { stdFoo: '' }] to the update method.
        // If you want to update component 1 and remove component 2, you should pass
        // `[{ stdFoo: '' }, null] to the update method.
        // A new component can be appended by adding a full properties object in the array.
        // If you have one component, and want to add a second one you should pass
        // `[{}, { stdFoo: '', stdBar: '' }] to the update method.
        var shouldRemoveSomeComponent = newProperties.some(function (props) { return !props; });
        if (!shouldRemoveSomeComponent && existingProperties && existingProperties.length >= newProperties.length) {
            // The number of component stays the same, so we can update each component individually with the new props
            for (var i = 0, iLen = newProperties.length; i < iLen; i++) {
                this[componentsPropertyName][i].update(newProperties[i]);
            }
        }
        else {
            // In this case the number of components changed.
            // Existing components might be removed and/or new ones added.
            // This means we first have to create the merged/updated props based on the existing props.
            // Then we re-render them all
            var newPropertiesMergedWithExistingProperties = [];
            for (var i = 0, iLen = Math.max((existingProperties === null || existingProperties === void 0 ? void 0 : existingProperties.length) || 0, newProperties.length); i < iLen; i++) {
                var existingProps = (existingProperties === null || existingProperties === void 0 ? void 0 : existingProperties[i]) || {};
                var newProps = newProperties[i] || null;
                var mergedProps = newProps ? __assign(__assign({}, existingProps), newProps) : null;
                newPropertiesMergedWithExistingProperties.push(mergedProps);
            }
            var newPropertiesWithoutRemovedComponents = newPropertiesMergedWithExistingProperties.filter(Boolean);
            container.innerHTML = this.renderPartialOfferPrice((_a = {},
                _a[propertiesPropertyName] = newPropertiesWithoutRemovedComponents,
                _a), containerClassName);
            this[componentsPropertyName] = mountingMethod(newPropertiesWithoutRemovedComponents);
        }
        // backsync the properties of the components to the offer price properties
        this.properties[propertiesPropertyName] = this[componentsPropertyName].map(function (component) { return component.getProperties(); });
    };
    /**
     * Updates the sublineText individually
     */
    OfferPrice.prototype.updateSubline = function (newSubline) {
        this.updateText('stdSubline', newSubline, this.getSublineTextContainer.bind(this));
    };
    /**
     * Updates the legalText individually
     * @todo add array logic
     */
    OfferPrice.prototype.updateLegalText = function (newLegalText) {
        this.updateText('txtLegalText', newLegalText, this.getLegalTextContainer.bind(this));
    };
    /**
     * Updates the additionalText individually
     */
    OfferPrice.prototype.updateAdditionalText = function (newAdditionalText) {
        this.updateText('txtAdditionalText', newAdditionalText, this.getAdditionalTextContainer.bind(this));
    };
    /**
     * Updates a text property individually
     * (stdSubline, txtLegalText, txtAdditionalText)
     */
    OfferPrice.prototype.updateText = function (propertyName, value, getContainerElement) {
        var _a;
        if (isEqual(this.properties[propertyName], value)) {
            return;
        }
        this.properties[propertyName] = value;
        var containerElement = getContainerElement();
        var hasValue = !!((_a = this.properties[propertyName]) === null || _a === void 0 ? void 0 : _a.length);
        containerElement.classList.toggle(CLASSNAME_HIDDEN, !hasValue);
        if (!hasValue) {
            containerElement.textContent = '';
            return;
        }
        var wrapTextInParagraph = function (text) { return "<p>".concat(text, "</p>"); };
        var html = Array.isArray(value)
            ? value.map(function (val) { return wrapTextInParagraph(val); }).join('')
            : wrapTextInParagraph(value);
        containerElement.innerHTML = html;
    };
    // endregion
    // #endregion
    // #region Rendering (VSCode)
    // region Rendering (Webstorm)
    /**
     * Renders the offer price partially and returns only the innerHTML of the container with the given class
     * @param properties The offerPrice properties
     * @param containerClassName The classname of the container
     * @returns The innerHTML of the found container
     */
    OfferPrice.prototype.renderPartialOfferPrice = function (properties, containerClassName) {
        return this.renderPartially(offerPriceTemplate, properties, ".".concat(containerClassName))[0];
    };
    // endregion
    // #endregion
    // #region HTML Getter Methods (VSCode)
    // region HTML Getter Methods (Webstorm)
    OfferPrice.prototype.getHighlightBadgeContainer = function () {
        return this.containerElement.getElementsByClassName(OFFER_PRICE_HIGHLIGHT_BADGE_CLASSNAME)[0];
    };
    OfferPrice.prototype.getHeadlineContainer = function () {
        return this.containerElement.getElementsByClassName(OFFER_PRICE_HEADLINE_CLASSNAME)[0];
    };
    OfferPrice.prototype.getPricesContainer = function () {
        return this.containerElement.getElementsByClassName(OFFER_PRICE_PRICES_CLASSNAME)[0];
    };
    OfferPrice.prototype.getPriceContainers = function () {
        return Array.from(this.getPricesContainer().getElementsByClassName(OFFER_PRICE_PRICE_CLASSNAME));
    };
    OfferPrice.prototype.getButtonsContainer = function () {
        return this.containerElement.getElementsByClassName(OFFER_PRICE_BUTTONS_CLASSNAME)[0];
    };
    OfferPrice.prototype.getButtonContainers = function () {
        return Array.from(this.getButtonsContainer().getElementsByClassName(OFFER_PRICE_BUTTON_CLASSNAME));
    };
    OfferPrice.prototype.getSublineTextContainer = function () {
        return this.containerElement.getElementsByClassName(OFFER_PRICE_SUBLINE_TEXT_CLASSNAME)[0];
    };
    OfferPrice.prototype.getLegalTextContainer = function () {
        return this.containerElement.getElementsByClassName(OFFER_PRICE_LEGAL_TEXT_CLASSNAME)[0];
    };
    OfferPrice.prototype.getAdditionalTextContainer = function () {
        return this.containerElement.getElementsByClassName(OFFER_PRICE_ADDITIONAL_TEXT_CLASSNAME)[0];
    };
    // endregion
    // #endregion
    // #region Toggle Methods (VSCode)
    // region Toggle Methods (Webstorm)
    /**
     * Toggles the highlightBadgeContainer visibility
     * @param shouldShow Optional boolean to force show/hide the container
     */
    OfferPrice.prototype.toggleHighlightBadgeContainer = function (shouldShow) {
        toggleElementVisibility(this.getHighlightBadgeContainer(), shouldShow);
    };
    /**
     * Toggles the headlineContainer visibility
     * @param shouldShow Optional boolean to force show/hide the container
     */
    OfferPrice.prototype.toggleHeadlineContainer = function (shouldShow) {
        toggleElementVisibility(this.getHeadlineContainer(), shouldShow);
    };
    /**
     * Toggles the priceContainer visibility (either all prices or a single price)
     * @param shouldShow Optional boolean to force show/hide the container
     * @param priceIndex Optional index to toggle a single price element instead of all prices
     */
    OfferPrice.prototype.togglePriceContainer = function (shouldShow, priceIndex) {
        var container;
        if (typeof priceIndex === 'number') {
            container = this.getPriceContainers()[priceIndex];
            if (!container) {
                throw new RangeError("Price container with index ".concat(priceIndex, " not found"));
            }
        }
        else {
            container = this.getPricesContainer();
        }
        toggleElementVisibility(container, shouldShow);
    };
    /**
     * Toggles the buttonContainer visibility (either all buttons or a single button)
     * @param shouldShow Optional boolean to force show/hide the container
     * @param buttonIndex Optional index to toggle a single button element instead of all buttons
     */
    OfferPrice.prototype.toggleButtonContainer = function (shouldShow, buttonIndex) {
        var container;
        if (typeof buttonIndex === 'number') {
            container = this.getButtonContainers()[buttonIndex];
            if (!container) {
                throw new RangeError("Button container with index ".concat(buttonIndex, " not found"));
            }
        }
        else {
            container = this.getButtonsContainer();
        }
        toggleElementVisibility(container, shouldShow);
    };
    /**
     * Toggles the sublineTextContainer visibility
     * @param shouldShow Optional boolean to force show/hide the container
     */
    OfferPrice.prototype.toggleSublineTextContainer = function (shouldShow) {
        toggleElementVisibility(this.getSublineTextContainer(), shouldShow);
    };
    /**
     * Toggles the legalTextContainer visibility
     * @param shouldShow Optional boolean to force show/hide the container
     */
    OfferPrice.prototype.toggleLegalTextContainer = function (shouldShow) {
        toggleElementVisibility(this.getLegalTextContainer(), shouldShow);
    };
    /**
     * Toggles the additionalTextContainer visibility
     * @param shouldShow Optional boolean to force show/hide the container
     */
    OfferPrice.prototype.toggleAdditionalTextContainer = function (shouldShow) {
        toggleElementVisibility(this.getAdditionalTextContainer(), shouldShow);
    };
    /**
     * Toggles the Sticky Price Bar Component
     * The Sticky Price Bar should be hidden when the Offer Price is in the viewport
     * or the viewport in under the Offer Price component‚
     * @param stickyPriceBar sticky price bar component
     */
    OfferPrice.prototype.toggleStickyPriceBar = function (stickyPriceBar) {
        var observer = new IntersectionObserver(function (entries) {
            entries.forEach(function (entry) {
                var entryRect = entry.boundingClientRect;
                var viewportHeight = window.innerHeight;
                stickyPriceBar.toggleIntersection(entry.isIntersecting || viewportHeight >= entryRect.bottom);
            });
        });
        observer.observe(this.containerElement);
    };
    return OfferPrice;
}(Pattern));
export { OfferPrice };
/**
 * This function returns a new instance of OfferPrice
 */
export var createOfferPrice = function (containerElement, businessLogicOrProperties) {
    var offerPrice = new OfferPrice(containerElement, businessLogicOrProperties);
    offerPrice.init();
    return offerPrice;
};
