var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import renderTemplate from './_loading-spinner.hbs';
import { getPropertiesFromClassName, Pattern, removeUndefinedFieldsFromObject, } from '@vfde-brix/ws10/core';
import { toggleElementVisibility } from '../core/utils/toggleElementVisibility';
import { LOADING_SPINNER_BASE_CLASSNAME, LOADING_SPINNER_CLASSNAME_INVERSE, LOADING_SPINNER_CLASSNAME_NESTED, LOADING_SPINNER_COLORS, LOADING_SPINNER_SIZES, } from './Constants';
import { CLASSNAME_HIDDEN } from '@vfde-brix/ws10/styles';
/**
 * Loading Spinner main class
 */
var LoadingSpinner = /** @class */ (function (_super) {
    __extends(LoadingSpinner, _super);
    function LoadingSpinner() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     * Intentionally return same props. Because no default props to set, but abstract needs implementation
     */
    LoadingSpinner.prototype.getDefaultProperties = function (newProperties) {
        return newProperties;
    };
    /**
     * Show the loading spinner
     */
    LoadingSpinner.prototype.show = function () {
        this.loadingSpinnerElement.classList.remove(CLASSNAME_HIDDEN);
    };
    /**
     * Hide the loading spinner
     */
    LoadingSpinner.prototype.hide = function () {
        this.loadingSpinnerElement.classList.add(CLASSNAME_HIDDEN);
    };
    /**
     * Toggle the loading spinner
     */
    LoadingSpinner.prototype.toggle = function (shouldShow) {
        toggleElementVisibility(this.loadingSpinnerElement, shouldShow);
    };
    /**
     * All rendering gets done with this function. If the component contains another
     * component, you would then render the child component in this function.
     */
    LoadingSpinner.prototype.writeDom = function () {
        !this.properties.optColor && (this.properties.optColor = 'primary');
        !this.properties.optSize && (this.properties.optSize = 'large');
        this.containerElement.innerHTML = renderTemplate(this.properties);
        this.loadingSpinnerElement = this.containerElement.getElementsByClassName(LOADING_SPINNER_BASE_CLASSNAME)[0];
    };
    /**
     * This is an abstract function so you have to implement it.
     *
     * If the html comes from the CMS, the component needs a way to know what the properties are,
     * so this gets the properties from the DOM elements
     *
     * You normally do not require this when you do not need to perform an update
     * Make this function only return undefined if you do not need properties.
     */
    LoadingSpinner.prototype.readDom = function (business) {
        this.loadingSpinnerElement = this.containerElement.getElementsByClassName(LOADING_SPINNER_BASE_CLASSNAME)[0];
        var optInverse = this.loadingSpinnerElement.classList.contains(LOADING_SPINNER_CLASSNAME_INVERSE);
        var optNested = this.loadingSpinnerElement.classList.contains(LOADING_SPINNER_CLASSNAME_NESTED);
        // arrays saved in the contants can be used to look for all variation values
        // using brix core getPropsFromClass fn
        var _a = getPropertiesFromClassName(this.loadingSpinnerElement.className, {
            color: LOADING_SPINNER_COLORS,
            size: LOADING_SPINNER_SIZES,
        }), color = _a.color, size = _a.size;
        var properties = {
            optInverse: optInverse,
            optColor: color,
            optSize: size,
            optNested: optNested,
            business: business,
        };
        return removeUndefinedFieldsFromObject(properties);
    };
    return LoadingSpinner;
}(Pattern));
export { LoadingSpinner };
/**
 * All components should have a factory function to create the component
 * This function returns an instance of LoadingSpinner
 */
export var createLoadingSpinner = function (containerElement, businessLogicOrProperties) {
    var loadingSpinner = new LoadingSpinner(containerElement, businessLogicOrProperties);
    loadingSpinner.init();
    return loadingSpinner;
};
